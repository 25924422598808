// import React, { useState, useEffect } from "react";
// import Button from "react-bootstrap/Button";
// import { ArrowLeft } from "react-bootstrap-icons";
// import { NavLink, useNavigate } from "react-router-dom";
// import Select from 'react-select';
// // import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import axios from "axios";
// import AdminRoute from "../../Route/RouteDetails";
// import { toast } from "react-toastify";
// import commoan from "../../environment/Config";
// import { countries, genders }  from './ConstantList';

// const Register = () => {
//   const mobile = localStorage.getItem("InputType");
//   //state for register client
//   // const [PhoneNumber, setPhoneNumber] = useState("")
//   const navigate = useNavigate();
//   const initialValues = {
//     firstName: "",
//     lastName: "",
//     birthDate: "",
//     phonenumber: mobile,
//     email: "",
//     otp: "",
//     country: "",
//     gender: "",
//     selectedCountry: null,
//     selectedGender: null,
//   };
  
//   const [formData, setFormData] = useState(initialValues);
//   const [formErrors, setFormErrors] = useState({});
//   const [isSubmit, setIsSubmit] = useState(false);

//   const RegisterValidate = (values, phone) => {
//     const errors = {};
//     // const mobileregex = /^[6-9]\d{9}$/gi;
//     var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
//     const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
//     // const dobRegex = /\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}\b/;
//     const dobregex = /^\d{4}-\d{2}-\d{2}$/;
//     const regexCountry = /^[A-Za-z]+$/;
//     const regexGender = /^(female|male)$/i;
//     const otpregex = /^\d{4}$/;
//     //validation
//     if (!values?.phonenumber) {
//       errors.phonenumber = "Phone Number is required";
//     }

//     //   else if (!mobileregex.test(phone)) {
//     //     errors.phone = "Enter valid Phone Number";
//     // }

//     if (!values?.firstName) {
//       errors.firstName = "First Name is required";
//     }

//     if (!values?.lastName) {
//       errors.lastName = "Last Name is required";
//     }
//     if (!values?.email) {
//       errors.email = "Email is required";
//     } else if (!emailRegrex.test(values?.email)) {
//       errors.email = "Enter valid Email";
//     }
//     if (!values?.country) {
//       errors.country = "Country is required";
//     } 
//     // else if (!regexCountry.test(values?.country)) {
//     //   console.log(values.country, "country")
//     //   errors.country = "Enter valid Country";
//     // }
//     if (!values?.gender) {
//       errors.gender = "Gender is required";
//     } else if (!regexGender.test(values?.gender)) {
//       errors.gender = "Enter valid Gender";
//     }
//     if (!values?.birthDate) {
//       errors.birthDate = "BirthDate is required";
//       console.log("values?.birthDate :",values?.birthDate)
//     } 
//     else if (!dobregex.test(values?.birthDate)) {
//       errors.birthDate = "Enter valid BirthDate";
//     }
//     if (!values?.otp) {
//       errors.otp = "Otp is required";
//     } else if (!otpregex.test(values?.otp)) {
//       errors.otp = "Enter 4 Digit otp";
//     }
//     return errors;
//   };

//   const handleChange = (e) => {
//     console.log(formData);
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   useEffect(() => {
//     console.log(formErrors);
//     if (Object.keys(formErrors).length === 0 && isSubmit) {
//       try {
//         let data = JSON.stringify({
//           name: `${formData?.firstName + " " + formData?.lastName}`,
//           email: formData?.email,
//           phone: formData?.phonenumber,
//           otp: formData?.otp,
//           gender: formData?.gender,
//           country: formData?.country,
//           birthDate: formData?.birthDate,
//         });

//         let config = {
//           method: "post",
//           maxBodyLength: Infinity,
//           url: `${commoan.baseUrl}auth/sign_up`,
//           headers: {
//             "Content-Type": "application/json",
//           },
//           data: data,
//         };

//         axios.request(config).then((response) => {
//           console.log(response?.data);
//           if (response?.data?.message === "Signup successful") {
//             // toast.success(response?.data?.message);
//             localStorage.setItem(
//               "AuthTokenAI",
//               JSON.stringify(response?.data?.data?.token)
//             );
//             navigate(`../${AdminRoute?.Common?.Plan}/1`);
//           } else {
//             toast.error(response?.data?.message);
//           }
//         });
//       } catch (error) {
//         console.log(error);
// 		toast.error(error?.message);
//       }
//     }
//   }, [formErrors]);

// //   useEffect(() => {
// //     console.log(formErrors);
// //   }, []);

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setFormErrors(RegisterValidate(formData));
//     setIsSubmit(true);
//   };

//   const handleCountryChange = (selectedCountry) => {
//     console.log(formData);
//       setFormData({
//         ...formData,
//         country: selectedCountry?.label,
//         selectedCountry: selectedCountry // Assuming 'country' is the name of the property for the selected country
//       });
    
//   };

//   const handleGenderChange = (selectedGender) => {
//     console.log(formData);
//       setFormData({
//         ...formData,
//         gender: selectedGender?.label,
//         selectedGender: selectedGender // Assuming 'country' is the name of the property for the selected country
//       });
    
//   };

//   //jsx
//   return (
//     <>
//       <div className="container">
//         <div className="row main-container">
//           <div className="col-md-6 col-sm-12 left-text">
//             <div className="logo-div">
//               <NavLink to="/">
//                 <img src="/images/logo.png" className="logo" alt="logo" />{" "}
//               </NavLink>
//             </div>
//             <h1 className="heading">Your 24/7 companion for unbiased, affordable therapy—anytime, anywhere.</h1>
//             {/* <p className="subheading">Mental Health journey</p>
//             <p>
//               through our online therapy programs, guided by our compassionate
//               team of psychologists.
//             </p> */}
//           </div>
//           {/* col-md-6 */}
//           <div className="col-md-6 col-sm-12">
//             <div className="register">
//               <h2 className="heading">
//                 {" "}
//                 <NavLink to="/">
//                   <ArrowLeft className="left-arrow" size={16} />
//                 </NavLink>
//                 {/* Registered */}
//                 Begin Your Personal Adventure
//               </h2>
//               <p className="heading-discription"> Let’s start this together. Create your account and step onto a path filled with growth, self-discovery, and healing.</p>
//               <form>
//                 <div>
//                   <label>First Name:</label>
//                   <input
//                     type="text"
//                     name="firstName"
//                     placeholder="Enter First Name"
//                     value={formData?.firstName}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors?.firstName}</p>
//                 </div>

//                 <div>
//                   <label>Last Name:</label>
//                   <input
//                     type="text"
//                     placeholder="Enter Last Name"
//                     name="lastName"
//                     value={formData?.lastName}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors.lastName}</p>
//                 </div>
//                 <div>
//                   <label>Gender:</label>
//                   {/*<input
//                     type="text"
//                     placeholder="Enter Male or Female"
//                     name="gender"
//                     value={formData?.gender}
//                     onChange={handleChange}
//                     required
//           />*/}
//           <Select
//                         name="country"
//                         placeholder="Enter Gender"
//                         value={formData?.selectedGender}
//                         onChange={handleGenderChange}
//                         options={genders}
//                         isSearchable={false}
//                         required
//                       />
//                   <p className="text-danger">{formErrors.gender}</p>
//                 </div>
//                 <div>
//                   <label>Country:</label>
//                   {/*<input
//                     type="text"
//                     placeholder="Enter Country"
//                     name="country"
//                     value={formData?.country}
//                     onChange={handleChange}
//                     required
//                   />*/}
//                   <Select
//                         name="country"
//                         placeholder="Enter Country"
//                         value={formData?.selectedCountry}
//                         onChange={handleCountryChange}
//                         isSearchable={false}
//                         options={countries}
//                         required
//                       />
//                   <p className="text-danger">{formErrors.country}</p>
//                 </div>
//                 <div>
//                   <label>Birth Date:</label>
//                   <input
//                     type="date"
//                     placeholder="Enter BirthDate"
//                     name="birthDate"
//                     value={formData?.birthDate}
//                     onChange={handleChange}
                 
//                     required
//                   />
//                    <p className="text-danger">{formErrors.birthDate}</p>
//                 </div>
//                 <div>
//                   <label>E-Mail ID:</label>
//                   <input
//                     type="email"
//                     placeholder="Enter E-Mail ID"
//                     name="email"
//                     value={formData?.email}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors?.email}</p>
//                 </div>
//                 <label>Mobile Number:</label>
//                 <div>
//                   {/* className="ph-input"<PhoneInput
//                     // country={"us"}
//                     placeholder="Enter Phone Number"
//                     onChange={value => setPhoneNumber(value)}
//                   /> */}
//                   <input
//                     type="text"
//                     name="phonenumber"
//                     value={formData?.phonenumber}
//                     placeholder="Enter Mobile Number"
//                     readOnly
//                     disabled
//                   />
//                   <p className="text-danger">{formErrors?.phonenumber}</p>
//                 </div>
//                 <div>
//                   <label>OTP:</label>
//                   <input
//                     type="number"
//                     placeholder="Enter 4-digit Otp"
//                     name="otp"
//                     maxLength={4}
//                     value={formData?.otp}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors?.otp}</p>
//                 </div>

//                 <Button
//                   className="button_common"
//                   onClick={(e) => handleSubmit(e)}
//                 >
//                   Verify 
//                 </Button>
//               </form>
//             </div>
//           </div>
//           {/* col-md-6 */}
//         </div>
//       </div>
//       {/* end of container */}
//     </>
//   );
// };

// export default Register;


//-------------------------------------------///

// import React, { useState, useEffect } from "react";
// import Button from "react-bootstrap/Button";
// import { ArrowLeft } from "react-bootstrap-icons";
// import { NavLink, useNavigate } from "react-router-dom";
// import Select from 'react-select';
// import axios from "axios";
// import AdminRoute from "../../Route/RouteDetails";
// import { toast } from "react-toastify";
// import commoan from "../../environment/Config";
// import { countries } from './ConstantList';
// import './Bubbles.css';

// const Register = () => {
//   const navigate = useNavigate();
//   const mobile = localStorage.getItem("InputType");

//   // State for form data
//   const [formData, setFormData] = useState({
//     name: "", // Combined first name and last name
//     birthDate: "",
//     email: "",
//     otp: "",
//     country: "", // Will be fetched automatically
//     mobile: mobile, // Pre-set mobile number
//     gender: ""
//   });

//   const [formErrors, setFormErrors] = useState({});
//   const [isSubmit, setIsSubmit] = useState(false);

//   useEffect(() => {
//     // Fetch country data from API
//     axios.get('https://api.ipbase.com/v1/json/')
//       .then(response => {
//         const countryData = response.data;
//         setFormData(prevState => ({
//           ...prevState,
//           country: countryData.country_name // Assuming the API returns country_name
//         }));
//       })
//       .catch(error => {
//         console.error('Error fetching country data:', error);
//       });
//   }, []);

//   const RegisterValidate = (values) => {
//     // Validation logic
//     // ...
//     const errors = {};
//     // const mobileregex = /^[6-9]\d{9}$/gi;
//     var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
//     const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
//     // const dobRegex = /\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}\b/;
//     // const dobregex = /^\d{4}-\d{2}-\d{2}$/;
//     const dobregex = /^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/](19\d{2}|20\d{2})$/; // Regex for DD/MM/YYYY or DD-MM-YYYY
//     const regexCountry = /^[A-Za-z]+$/;
//     // const regexGender = /^(female|male)$/i;
//     const regexGender = /^(male|female|other)$/i;
//     const otpregex = /^\d{4}$/;
//     //validation
//     if (!values?.mobile) {
//       errors.phonenumber = "Phone Number is required";
//     }

//     //   else if (!mobileregex.test(phone)) {
//     //     errors.phone = "Enter valid Phone Number";
//     // }

//     if (!values?.name) {
//       errors.name = "Name is required";
//     }

//     // if (!values?.lastName) {
//     //   errors.lastName = "Last Name is required";
//     // }
//     if (!values?.email) {
//       errors.email = "Email is required";
//     } else if (!emailRegrex.test(values?.email)) {
//       errors.email = "Enter valid Email";
//     }
//     // if (!values?.country) {
//     //   errors.country = "Country is required";
//     // } 
//     // else if (!regexCountry.test(values?.country)) {
//     //   console.log(values.country, "country")
//     //   errors.country = "Enter valid Country";
//     // }
//     if (!values?.gender) {
//       errors.gender = "Gender is required";
//     }
//     //  else if (!regexGender.test(values?.gender)) {
//     //   errors.gender = "Enter valid Gender : Male/Female/Others";
//     // }
//     if (!values?.birthDate) {
//       errors.birthDate = "BirthDate is required";
//       console.log("values?.birthDate :",values?.birthDate)
//     } 
//     else if (!dobregex.test(values?.birthDate)) {
//       errors.birthDate = "Enter valid BirthDate";
//     }
//     if (!values?.otp) {
//       errors.otp = "Otp is required";
//     } else if (!otpregex.test(values?.otp)) {
//       errors.otp = "Enter 4 Digit otp";
//     }

//     return errors;
//   };

//   const genderOptions = [
//     { value: "male", label: "Male" },
//     { value: "female", label: "Female" },
//     { value: "other", label: "Others" },
//   ];

//   useEffect(() => {
//         console.log(formErrors);
//         if (Object.keys(formErrors).length === 0 && isSubmit) {
//           try {
//             let data = JSON.stringify({
//               name: formData?.name,
//               email: formData?.email,
//               phone: formData?.mobile,
//               otp: formData?.otp,
//               gender: formData?.gender,
//               country: formData?.country,
//               birthDate: formData?.birthDate,
//             });
    
//             let config = {
//               method: "post",
//               maxBodyLength: Infinity,
//               url: `${commoan.baseUrl}auth/sign_up`,
//               headers: {
//                 "Content-Type": "application/json",
//               },
//               data: data,
//             };
//             console.log("config :", config)
//             axios.request(config).then((response) => {
//               console.log(response?.data);
//               if (response?.data?.message === "Signup successful") {
//                 // toast.success(response?.data?.message);
//                 localStorage.setItem(
//                   "AuthTokenAI",
//                   JSON.stringify(response?.data?.data?.token)
//                 );
//                 navigate(`../${AdminRoute?.Common?.Plan}/1`);
//               } else {
//                 toast.error(response?.data?.message);
//               }
//             });
//           } catch (error) {
//             console.log(error);
//     		toast.error(error?.message);
//           }
//         }
//       }, [formErrors]);


//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // const customStyles = {
//   //   control: (provided, state) => ({
//   //     ...provided,
//   //     border: state.isFocused ? "2px solid #007bff" : "2px solid #ced4da",
//   //     boxShadow: "none",
//   //     "&:hover": {
//   //       border: "2px solid #007bff",
//   //     },
//   //   }),
//   // };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setFormErrors(RegisterValidate(formData));
//     setIsSubmit(true);
//   };

//   const handleGenderChange = (selectedGender) => {
//     setFormData({
//       ...formData,
//       gender: selectedGender?.value,
//     });
//   };

//   return (
//     <>
    
//         <div className="container">
//          <div className="row main-container">
//           <div className="col-md-6 col-sm-12 left-text">
//            <div className="logo-div">
//             <NavLink to="/">
//               <img src="/images/logo.png" className="logo" alt="logo" />{" "}
//               </NavLink>
//              </div>
//             <h1 className="heading">Your 24/7 companion for unbiased, affordable therapy—anytime, anywhere.</h1>
//              {/* <p className="subheading">Mental Health journey</p>
//              <p>
//                through our online therapy programs, guided by our compassionate
//               team of psychologists.
//              </p> */}
//           </div>
//           {/* col-md-6 */}
//           {/* Right Section */}
//           <div className="col-md-6 col-sm-12">
//             <div className="register">
//               <h2 className="heading">
//                 <NavLink to="/">
//                   <ArrowLeft className="left-arrow" size={16} />
//                 </NavLink>
//                 Begin Your Personal Adventure
//               </h2>
//               <p className="heading-discription">
//                 Let’s start this together. Create your account and step onto a path filled with growth, self-discovery, and healing.
//               </p>
//               <form onSubmit={handleSubmit}>
//                 <div>
//                   <label>Name:</label>
//                   <input
//                     type="text"
//                     name="name"
//                     placeholder="Enter Name"
//                     value={formData.name}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors.name}</p>
//                 </div>


//                 {/* <div>
//                   <label>Country:</label>
//                   <input
//                     type="text"
//                     placeholder="Country"
//                     value={formData.country}
//                     disabled
//                   />
//                   <p className="text-danger">{formErrors.country}</p>
//                 </div> */}

//                 <div>
//                   <label>Birth Date:</label>
//                   <input
//                     type="text"
//                     placeholder="DD-MM-YYYY"
//                     name="birthDate"
//                     value={formData.birthDate}
//                     onChange={handleChange}
//                     maxLength={10} // Maximum length for YYYY-MM-DD
//                     required
//                   />
//                   <p className="text-danger">{formErrors.birthDate}</p>
//                 </div>

//                 <div>
//                   <label>E-Mail ID:</label>
//                   <input
//                     type="email"
//                     placeholder="Enter E-Mail ID"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors.email}</p>
//                 </div>

//                 {/* <div>
//                   <label>Mobile Number:</label>
//                   <input
//                     type="text"
//                     name="phonenumber"
//                     value={formData.mobile}
//                     readOnly
//                     disabled
//                   />
//                   <p className="text-danger">{formErrors.mobile}</p>
//                 </div> */}

//                 <div>
//                   <label>OTP:</label>
//                   <input
//                     type="number"
//                     placeholder="Enter 4-digit Otp"
//                     name="otp"
//                     maxLength={4}
//                     value={formData.otp}
//                     onChange={handleChange}
//                     required
//                   />
//                   <p className="text-danger">{formErrors.otp}</p>
//                 </div>
//                 <div>
//                   {/* <label>Gender:</label> */}
//                   {/* Selection bubbles */}
//                   {/* <div> */}
//                   <label>Gender:</label>
//                   <div className="gender-bubbles">
//                     {/* <label>
//                       <input
//                         type="radio"
//                         name="gender"
//                         value="male"
//                         onChange={() => handleGenderChange({ value: "male" })}
//                       /> Male
//                     </label> */}
                    
//                     <label>
//                      <input
//                         type="radio"
//                         name="gender"
//                         value="male"
//                         onChange={handleChange}
//                       />{" "}
//                       <span className="bubble">Male</span>
//                       </label>
//                     {/* <label>
//                       <input
//                         type="radio"
//                         name="gender"
//                         value="female"
//                         onChange={() => handleGenderChange({ value: "female" })}
//                       /> Female
//                     </label> */}
//                     <label>
//                       <input
//                         type="radio"
//                         name="gender"
//                         value="female"
//                         onChange={handleChange}
//                       />{" "}
//                       <span className="bubble">Female</span>
//                     </label>
//                     {/* <label>
//                       <input
//                         type="radio"
//                         name="gender"
//                         value="other"
//                         onChange={() => handleGenderChange({ value: "other" })}
//                       /> Others
//                     </label> */}
//                      <label>
//                       <input
//                         type="radio"
//                         name="gender"
//                         value="other"
//                         onChange={handleChange}
//                       />{" "}
//                       <span className="bubble">Other</span>
//                     </label>
//                   </div>
//                   <p className="text-danger">{formErrors.gender}</p>
//                 </div>
                
//                  {/* <div>
//                   <label>Gender:</label>
//                   <input
//                     type="text"
//                     name="gender"
//                     placeholder="Male/Female/Others"
//                     value={formData.gender}
//                     onChange={handleChange}
//                     required
//                   /> 
//                   <p className="text-danger">{formErrors.gender}</p>
//                 </div> */}

//                 <Button className="button_common" type="submit">
//                   Verify
//                 </Button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Register;

//==================================================================//

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink, useNavigate } from "react-router-dom";
import Select from 'react-select';
import axios from "axios";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
import commoan from "../../environment/Config";
import { countries } from './ConstantList';
import './Bubbles.css';

const Register = () => {
  const navigate = useNavigate();
  const mobile = localStorage.getItem("InputType");

  // State for form data
  const [formData, setFormData] = useState({
    name: "", // Combined first name and last name
    birthDate: "",
    email: "",
    otp: "",
    country: "", // Will be fetched automatically
    mobile: mobile, // Pre-set mobile number
    gender: "",
    dateString:"",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    // Fetch country data from API
    axios.get('https://api.ipbase.com/v1/json/')
      .then(response => {
        const countryData = response.data;
        setFormData(prevState => ({
          ...prevState,
          country: countryData.country_name // Assuming the API returns country_name
        }));
      })
      .catch(error => {
        console.error('Error fetching country data:', error);
      });
  }, []);

  const RegisterValidate = (values) => {
    // Validation logic
    // ...
    const errors = {};
    // const mobileregex = /^[6-9]\d{9}$/gi;
    var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    const emailRegrex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})*$/;
    // const dobRegex = /\b(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}\b/;
    // const dobregex = /^\d{4}-\d{2}-\d{2}$/;
    const dobregex = /^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[0-2])[-/](19\d{2}|20\d{2})$/; // Regex for DD/MM/YYYY or DD-MM-YYYY
    // const dobregex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19\d\d|20\d\d)$/;
    // const dobregex =  /^(0[1-9]|1[0-2])(\/|-)(0[1-9]|1\d|2\d|3[01])(\/|-)(19\d\d|20\d\d)$/;

    const regexCountry = /^[A-Za-z]+$/;
    // const regexGender = /^(female|male)$/i;
    const regexGender = /^(male|female|other)$/i;
    const otpregex = /^\d{4}$/;
    //validation
    if (!values?.mobile) {
      errors.phonenumber = "Phone Number is required";
    }

    //   else if (!mobileregex.test(phone)) {
    //     errors.phone = "Enter valid Phone Number";
    // }

    if (!values?.name) {
      errors.name = "Name is required";
    }

    // if (!values?.lastName) {
    //   errors.lastName = "Last Name is required";
    // }
    if (!values?.email) {
      errors.email = "Email is required";
    } else if (!emailRegrex.test(values?.email)) {
      errors.email = "Enter valid Email";
    }
    // if (!values?.country) {
    //   errors.country = "Country is required";
    // } 
    // else if (!regexCountry.test(values?.country)) {
    //   console.log(values.country, "country")
    //   errors.country = "Enter valid Country";
    // }
    if (!values?.gender) {
      errors.gender = "Gender is required";
    }
    //  else if (!regexGender.test(values?.gender)) {
    //   errors.gender = "Enter valid Gender : Male/Female/Others";
    // }
    if (!values?.birthDate) {
      errors.birthDate = "BirthDate is required";
      // console.log("values?.birthDate :",values?.birthDate)
    } 
    // else if (!dobregex.test(values?.birthDate)) {
    //   errors.birthDate = "Enter valid BirthDate";
    // }
    if (!values?.otp) {
      errors.otp = "Otp is required";
    } else if (!otpregex.test(values?.otp)) {
      errors.otp = "Enter 4 Digit otp";
    }

    return errors;
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Others" },
  ];

  useEffect(() => {
        // console.log(formErrors);
        if (Object.keys(formErrors).length === 0 && isSubmit  && isChecked) {
          try {
            let data = JSON.stringify({
              name: formData?.name,
              email: formData?.email,
              phone: formData?.mobile,
              otp: formData?.otp,
              gender: formData?.gender,
              country: formData?.country,
              birthDate: formData?.birthDate,
            });
    
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${commoan.baseUrl}auth/sign_up`,
              headers: {
                "Content-Type": "application/json",
              },
              data: data,
            };
            // console.log("config :", config)
            axios.request(config).then((response) => {
              console.log(response?.data);
              if (response?.data?.message === "Signup successful") {
                // toast.success(response?.data?.message);
                localStorage.setItem(
                  "AuthTokenAI",
                  JSON.stringify(response?.data?.data?.token)
                );
                navigate(`../${AdminRoute?.Common?.Plan}/1`);
              } else {
                toast.error(response?.data?.message);
              }
            });
          } catch (error) {
            console.log(error);
    		toast.error(error?.message);
          }
        }
      }, [formErrors, isChecked]);


  const handleChange = (e) => {
    // debugger;
    // console.log( "e.target :",e.target);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // const customStyles = {
  //   control: (provided, state) => ({
  //     ...provided,
  //     border: state.isFocused ? "2px solid #007bff" : "2px solid #ced4da",
  //     boxShadow: "none",
  //     "&:hover": {
  //       border: "2px solid #007bff",
  //     },
  //   }),
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(RegisterValidate(formData));
    setIsSubmit(true);
  };

  const handleGenderChange = (selectedGender) => {
    setFormData({
      ...formData,
      gender: selectedGender?.value,
    });
  };
  const toggleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const resendOTP = () => {
    try {
      // debugger;
      let data = JSON.stringify({
        phone: formData?.mobile,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}auth/otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        // console.log(response?.data);
        // Handle response...
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }
  };

  const handleDate = (e) => {
    const { name, value } = e.target;
    debugger;
    if (!value) return ''; // handle empty or undefined case
    const [year, month, day] = value.split('-');
    const datevalue =  `${day}/${month}/${year}`;
    formData.dateString = value;
    setFormData({
      ...formData,
      // birthDate: datevalue,
      birthDate: formData.dateString
    });
  };

  return (
    <>
    
        <div className="container">
         <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
           <div className="logo-div">
            <NavLink to="/">
              <img src="/images/logo.png" className="logo" alt="logo" />{" "}
              </NavLink>
             </div>
            <h1 className="heading">Your 24x7 Wellness Companion for</h1>
            <h1 className="heading">Unbiased Support.</h1>
             {/* <p className="subheading">Mental Health journey</p>
             <p>
               through our online therapy programs, guided by our compassionate
              team of psychologists.
             </p> */}
          </div>
          {/* col-md-6 */}
          {/* Right Section */}
          <div className="col-md-6 col-sm-12">
            <div className="register">
              <h2 className="heading">
                <NavLink to="/">
                  <ArrowLeft className="left-arrow" size={16} />
                </NavLink>
                Begin Your Wellness Journey
              </h2>
              <p className="heading-discription">
                Let’s start this together. Create your account and step onto a path filled with growth, self-discovery, and healing.
              </p>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <p className="text-danger">{formErrors.name}</p>
                </div>


                {/* <div>
                  <label>Country:</label>
                  <input
                    type="text"
                    placeholder="Country"
                    value={formData.country}
                    disabled
                  />
                  <p className="text-danger">{formErrors.country}</p>
                </div> */}

                {/* <div>
                  <label>Birth Date:</label>
                  <input
                    type="text"
                    placeholder="DD-MM-YYYY"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                    maxLength={10} // Maximum length for YYYY-MM-DD
                    required
                  />
                  <p className="text-danger">{formErrors.birthDate}</p>
                </div>*/}

{/* <div>
  <label>Birth Date:</label>
  <DatePicker
    selected={formData.birthDate}
    onChange={(date) => setFormData({ ...formData, birthDate: date })}
    dateFormat="dd/MM/yyyy"
    placeholderText="DD/MM/YYYY"
    maxDate={new Date()} // Prevent selecting future dates
    showYearDropdown
    showMonthDropdown
    dropdownMode="select"
    required
  />
  <p className="text-danger">{formErrors.birthDate}</p>
</div> */}

                <div>
                  <label>E-Mail ID</label>
                  <input
                    type="email"
                    placeholder="Enter E-Mail ID"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <p className="text-danger">{formErrors.email}</p>
                </div>
                <div>
                  <label>Date of Birth</label>
<div className="input-with-calendar">
                    <input
                      type="date"
                      // placeholder="DD/MM/YYYY"
                      placeholder="dd-mm-yyyy" 
                      name="birthDate"
                      value={formData.dateString}
                      onChange={handleDate}
                      maxLength={10}
                      max="9999-12-31"
                      required
                    />
                   
  {/* <input
    type="date"
    name="birthDate"
    value={formatDate(formData.birthDate)}
    onChange={handleChange}
    max="9999-12-31"
    required
  /> */}

                    {/* <button type="button" className="calendar-button">
                      Calendar
                    </button> */}
                  </div>
                  <p className="text-danger">{formErrors.birthDate}</p>
                </div>
                {/* <div>
                  <label>Mobile Number:</label>
                  <input
                    type="text"
                    name="phonenumber"
                    value={formData.mobile}
                    readOnly
                    disabled
                  />
                  <p className="text-danger">{formErrors.mobile}</p>
                </div> */}

                <div>
                  <label>OTP</label>
                  <input
                    type="number"
                    placeholder="Enter 4-digit Otp"
                    name="otp"
                    maxLength={4}
                    value={formData.otp}
                    onChange={handleChange}
                    required
                  />
                  <p className="text-danger">{formErrors.otp}</p>
                </div>
                <div>
                  {/* <label>Gender:</label> */}
                  {/* Selection bubbles */}
                  {/* <div> */}
                  <label>Gender</label>
                  <div className="gender-bubbles">
                    {/* <label>
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        onChange={() => handleGenderChange({ value: "male" })}
                      /> Male
                    </label> */}
                    
                    <label>
                     <input
                        type="radio"
                        name="gender"
                        value="male"
                        onChange={handleChange}
                      />{" "}
                      <span className="bubble">Male</span>
                      </label>
                    {/* <label>
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        onChange={() => handleGenderChange({ value: "female" })}
                      /> Female
                    </label> */}
                    <label>
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        onChange={handleChange}
                      />{" "}
                      <span className="bubble">Female</span>
                    </label>
                    {/* <label>
                      <input
                        type="radio"
                        name="gender"
                        value="other"
                        onChange={() => handleGenderChange({ value: "other" })}
                      /> Others
                    </label> */}
                     <label>
                      <input
                        type="radio"
                        name="gender"
                        value="other"
                        onChange={handleChange}
                      />{" "}
                      <span className="bubble">Other</span>
                    </label>
                  </div>
                  <p className="text-danger">{formErrors.gender}</p>
                </div>
                
                 {/* <div>
                  <label>Gender:</label>
                  <input
                    type="text"
                    name="gender"
                    placeholder="Male/Female/Others"
                    value={formData.gender}
                    onChange={handleChange}
                    required
                  /> 
                  <p className="text-danger">{formErrors.gender}</p>
                </div> */}
                 <div className="terms-condition">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    checked={isChecked}
                    onChange={toggleCheckbox}
                    required
                  />
                  <label htmlFor="terms">
                  <a href="https://rytlife.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                    I have read and accepted the terms and conditions
                    </a>
                    </label>
                </div>

                <Button className="button_common" type="submit">
                  Verify
                </Button>

                 {/* Resend OTP Button */}
                 <p className="resend-otp-text" onClick={resendOTP}>
                  <span style={{ fontWeight: 400 }}>Resend OTP</span>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
