import React, { useEffect } from "react";
import axios from "axios";
import commoan from "../../environment/Config";
import { useState } from "react";
import AdminRoute from "../../Route/RouteDetails";

import { useNavigate, useParams } from "react-router-dom";
import backBtn from "../../Assets/images/Icon ionic-ios-arrow-back.png";

const Plan = () => {
  const param = useParams();
  const authToken = JSON.parse(localStorage.getItem("AuthTokenAI"));
  const [PLanList, setPLanList] = useState([]);
  const [UserInfo, setUserInfo] = useState([]);
  const [getSUb_status, setgetSUb_status] = useState("");
  const [couponCode, setCouponCode] = useState("");

  const navigate = useNavigate();

  const onBackClick = () => {
    // navigate(`../${AdminRoute?.Common?.Aitherapist}`);
    navigate(-1)
  };

  useEffect(() => {
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${commoan.baseUrl}getUserInfo`,
      headers: {
        Authorization: authToken,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.data?.user);
        setUserInfo(response?.data?.data?.user);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPLans = () => {
    try {
      let data = "";
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${commoan?.baseUrl}subscription/getPlans`,
        headers: {
          authorization: authToken,
        },
        data: data,
      };

      axios.request(config).then((response) => {
        console.log("Plan list===>", response?.data?.plans);
        setPLanList(response?.data?.plans);
      });
    } catch (error) {
      console.log(error);
    }
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${commoan?.baseUrl}subscription/getSubscriptionStatus`,
        headers: {
          authorization: authToken,
        },
      };
      axios.request(config).then((response) => {
        console.log("getSubscriptionStatus===>", response.data);
        if(response?.data?.subscription_flag === true){
        setgetSUb_status(response?.data);
      }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPLans();
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function showRazorpay(amount, planid) {
    // if (planid === 3) {
    //   navigate(`../${AdminRoute?.Common?.Aitherapist}`);
    // } else {
      try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${commoan?.baseUrl}subscription/getkey?`,
        headers: {
          Authorization: authToken,
        },
      };

      const key = await axios.request(config);
      console.log("key  :", key);
      var data = await fetch(
        `${commoan?.baseUrl}subscription/checkout`,
        {
          method: "POST",
          headers: {
            authorization: authToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            planId: planid,
            couponCode : couponCode
          }),
          redirect: "follow",
        }
      ).then((t) => t.json());
        let amount = data?.subscription?.sub_amount * 100;
      console.log("data amount", data,amount );
      const options = {
        key: key.data.key,
        amount:data?.subscription?.sub_amount * 100,
        currency: "INR",
        name: "Rytlife",
        description: "Thank you for purchase our service/product",
        image:
          "https://cdn.shopify.com/s/files/1/1684/4603/products/MacBookPro13_Mid2012_NonRetina_Silver.png",
        order_id: data?.subscription?.recurring_payment ?  "" : data?.subscription?.invoice_id ,
        subscription_id: data?.subscription?.recurring_payment ?  data?.subscription?.invoice_id : "",
        callback_url:
        `${commoan?.baseUrl}subscription/paymentVerification`,
        prefill: {
          name: UserInfo?.name, //logged in  user details
          email: UserInfo?.email,
          contact: UserInfo?.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },

        prefill: {
          name: UserInfo?.name,
          phone_number: UserInfo?.phone,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  }

  async function showRazorpayMonthly( amount,planid) {
    // if (planid === 3) {
    //   navigate(`../${AdminRoute?.Common?.Aitherapist}`);
    // } else {
      try {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${commoan?.baseUrl}subscription/getkey?`,
        headers: {
          Authorization: authToken,
        },
      };

      const key = await axios.request(config);
      console.log("key  :", key);
      var data = await fetch(
        `${commoan?.baseUrl}subscription/autoSubsriptionCheckout`,
        {
          method: "POST",
          headers: {
            authorization: authToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            planId: planid,
          }),
          redirect: "follow",
        }
      ).then((t) => t.json());

      console.log("data", data);
      const options = {
        key: key.data.key,
        amount: amount * 100,
        currency: "INR",
        name: "Rytlife",
        description: "Thank you for purchase our service/product",
        image:
          "https://cdn.shopify.com/s/files/1/1684/4603/products/MacBookPro13_Mid2012_NonRetina_Silver.png",
        subscription_id: data?.autoSubsriptionId,
        recurring: true,
        callback_url:
        `${commoan?.baseUrl}subscription/autoSubsriptionPaymentVerification`,
        prefill: {
          name: UserInfo?.name, //logged in  user details
          email: UserInfo?.email,
          contact: UserInfo?.phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },

        prefill: {
          name: UserInfo?.name,
          phone_number: UserInfo?.phone,
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  }

  // Function to handle coupon code input change
  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  // if(getSUb_status?.plan[0]?.plan_id === 3 &&
  //   getSUb_status?.subscription?.seconds >= 0){
  // navigate(`../${AdminRoute?.Common?.Aitherapist}`);
  //   }
  // const updatedata = (response, data) =>
  //   fetch("http://localhost:5000/razorpay/paymentUpdate", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       pay_for: "product",
  //       _id: data.data._id,
  //       payment_id: response.razorpay_payment_id,
  //       order_id: response.razorpay_order_id,
  //       signature_id: response.razorpay_signature,
  //       is_paid: 1,
  //     }),
  //     redirect: "follow",
  //   }).then((t) => t.json());

  //jsx
  return (
    <div className="app-container">
      <div className="plans-container">
        <div className="plan-lan-head">
          {param?.id === "2" ? (
            <span onClick={() => onBackClick()}>
              <img
                src={backBtn}
                style={{
                  height: "30px",
                  height: "23px",
                  marginLeft: "16px",
                  opacity: "0.7",
                }}
              />

              <label className="bck-lbl">Back to Home</label>
            </span>
          ) : (
            <></>
          )}

          <p className="plan-lan-head-lbl">Pricing That Cares</p>
          <p className="plan-lan-head-lbl2">
            Help us serve you better by selecting a plan that suits you best
          </p>
        </div>
        <div className="coupon-code-container">
          <input
            type="text"
            placeholder="Enter Coupon Code"
            value={couponCode}
            onChange={handleCouponCodeChange}
            className="coupon-code-input"
          />
          {/* You can add a button here to apply the coupon code */}
        </div>

        <div className="plans-arr">
          {PLanList?.length &&
            PLanList?.map((val, i) =>
              (getSUb_status === "" && val?.plan_name === "Trial")  || val?.plan_name === "Basic" || val?.plan_name === "Premium" ?
              //   getSUb_status?.plan[0]?.plan_id === 3 &&
              //   getSUb_status?.subscription?.seconds >= 0 &&
              //   val?.plan_name === "Trial") ||
              // val?.plan_name === "Basic" ||
              // val?.plan_name === "Standard" ||
              // val?.plan_name === "Premium" ? 
              (
                <div
                  key={i}
                  className="plan-box cursor-pointer"
                  onClick={() => showRazorpay(val?.amount, val?.plan_id)}
                  // onClick={()=> alert("ok")}
                >
                  <div
                    className={
                      val?.plan_name === "Basic" ? (
                        "first"
                      ) 
                      // : val?.plan_name === "Standard" ? (
                      //   "sec"
                      // )
                       : val?.plan_name === "Premium" ? (
                        // "first"
                        "sec"
                      ) : val?.plan_name === "Trial" ? (
                        "thir"
                      ) : (
                        <></>
                      )
                    }
                  >
                    <p className="card-head">{val?.plan_name}</p>
                    <p className="card-sub-head">INR {val?.amount}/-</p>
                    {/* <p>Monthly Subcription: {val?.number_of_days} Days</p>
                    <p>Number of hrs: {val?.plan_name == "Trial" ?val?.seconds / 60 : val?.seconds / 60 / 60} {val?.plan_name == "Trial" ? "Mins":"Hrs"}</p> */}
                    {Object.values(val?.features[0]).map((feature, index) => (
    <p key={index + index}>{feature}</p>
  ))}
                  </div>
                  {/* <div className="fe-container">
                    <p className="fe-lbl">Feature</p>
                    <ul>
                      {val?.features.length &&
                        val?.features.map((v, i) => {
                          return (
                            <li key={i}>
                              {Object.keys(v).map((key) => (
                                <p key={key}>
                                  {key}: {v[key].toString()}
                                </p>
                              ))}
                            </li>
                          );
                        })}
                    </ul>
                  </div> */}
                </div>
              ) : (
                <></>
              )
            )}
            {PLanList?.length &&
            PLanList?.map((val, i) =>
               val?.plan_name === "Basic Monthly" || val?.plan_name === "Premium Monthly" ?
              //   getSUb_status?.plan[0]?.plan_id === 3 &&
              //   getSUb_status?.subscription?.seconds >= 0 &&
              //   val?.plan_name === "Trial") ||
              // val?.plan_name === "Basic" ||
              // val?.plan_name === "Standard" ||
              // val?.plan_name === "Premium" ? 
              (
                <div
                  key={i}
                  className="plan-box cursor-pointer"
                  // onClick={() => showRazorpayMonthly(val?.amount,val?.plan_id)}
                  onClick={() => showRazorpay(val?.amount,val?.plan_id)}
                  // onClick={()=> alert("ok")}
                >
                  <div
                    className={
                      val?.plan_name === "Basic Monthly" ? (
                        "first"
                      ) 
                      // : val?.plan_name === "Standard" ? (
                      //   "sec"
                      // )
                       : val?.plan_name === "Premium Monthly" ? (
                        // "first"
                        "sec"
                      ) : (
                        <></>
                      )
                    }
                  >
                    <p className="card-head">{val?.plan_name}</p>
                    <p className="card-sub-head">INR {val?.amount}/-</p>
                    {/* <p>Monthly Subcription: {val?.number_of_days} Days</p>
                    <p>Number of hrs: { val?.seconds / 60 / 60} "Hrs"</p> */}
                    {Object.values(val?.features[0]).map((feature, index) => (
    <p key={index + index}>{feature}</p>
  ))}
                  </div>
                  {/* <div className="fe-container">
                    <p className="fe-lbl">Feature</p>
                    <ul>
                      {val?.features.length &&
                        val?.features.map((v, i) => {
                          return (
                            <li key={i}>
                              {Object.keys(v).map((key) => (
                                <p key={key}>
                                  {key}: {v[key].toString()}
                                </p>
                              ))}
                            </li>
                          );
                        })}
                    </ul>
                  </div> */}
                </div>
              ) : (
                <></>
              )
            )}
        </div>
      </div>
    </div>
  );
};

export default Plan;
