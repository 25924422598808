import React from "react";
import failedImg from '../../Assets/images/Icon material-close.png';
import { useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";

const Failed = () => {
    const navigate = useNavigate();
    const navigatetopage =()=>{
    //   navigate(`../${AdminRoute?.Common?.Plan}/1`);
    navigate(-1)
    }
    return (
        <div className="success-container">
            <div className="masge-card">
                <img className="failed-img" src={failedImg} />
                <p className="my-4 txt-center succes-lbl">
                    Payment Failed
                </p>
                <p className="my-3 txt-center info-lbl ">
                    Your payment wasn’t successful. Try again!
                    If money was debited from your account, it will be automatically refunded in 5-7 working days.</p>

                <button type="submit" className="button_common w-100 mt-3 mb-3" role="button"
                onClick={(e)=> navigatetopage(e)}
                >Go Back </button>
            </div>
        </div>
    )
}

export default Failed;