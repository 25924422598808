// import Page from "../Components/Page";
import React from "react";
import RouteName from "./RouteDetails";
import Login from "./../Components/Auth/Login";
import VerifyOtp from "../Components/Auth/VerifyOtp";
import Register from "../Components/Auth/Register";
import Aitherapist from "../Components/AITherapist/Aitherapist";
import Plan from "../Components/Plan/Plan";
import Success from "../Components/Plan/Success";
import Failed from "../Components/Plan/Failed";
import CommonTable from "../Components/Common/table";
import ProtectedRoute from "./ProtectedRoute";
import PlanHistory from "../Components/Plan/PlanHistory"
import Sample from "../Components/Common/Sample";
// import PlanPage from "../Components/Plan/PlanPage";

// const loggedin = localStorage.getItem("isLoggedIn");

//routes
const AdminRoute = [
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
  {
    path: RouteName.Auth.VerifyOtp,
    element: <VerifyOtp />,
    exact: true,
  },

  {
    path: RouteName.Auth.Register,
    element: <Register />,
    exact: true,
  },

  // {
  //   path: loggedin ? RouteName.Common.Aitherapist : RouteName.Auth.Login,
  //   element: loggedin ? <Aitherapist /> : <Login />,
  //   exact: true,
  // },

  {
    path: RouteName.Common.Aitherapist,
    element: (
      <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
        <Aitherapist />{" "}
      </ProtectedRoute>
    ),
    exact: true,
  },

  {
    path: `${RouteName.Common.Plan}/:id`,
    element: <Plan />,
    exact: true,
  },

  {
    path: RouteName.Common.Success,
    element: <Success />,
    exact: true,
  },

  {
    path: RouteName.Common.Failed,
    element: <Failed />,
    exact: true,
  },

  {
    path: RouteName.Common.CommonTable,
    element: <CommonTable/>,
    exact: true,
  },
  

  {
    path: RouteName.Common.PlanHistory,
    element: <PlanHistory/>,
    exact: true,
  },

  {
    path: RouteName.Common.Sample,
    element: <Sample/>,
    exact: true,
  },

  // {
  //   path: RouteName.Common.PlanPage,
  //   element: <PlanPage/>,
  //   exact: true,
  // },
  // {
  //   path: RouteName.Auth.Success,
  //   element: <Success />,
  //   exact: true,
  // },
  // {
  //   path: RouteName.Auth.ResendOtp,
  //   element: <ResendOtp />,
  //   exact: true,
  // },
  // {
  //   path: RouteName.Groups.Group,
  //   element: <Group/>,
  //   exact: true,
  // },

  // {
  //   path: RouteName.Header.Header,
  //   element: <Header/>,
  //   exact: true,
  // },

  // {
  //   path: RouteName.Footer.Footer,
  //   element: <Footer/>,
  //   exact: true,
  // },
  // {
  //   path: RouteName.Sidebar.Sidebar,
  //   element: <Sidebar/>,
  //   exact: true,
  // },

  /////////////////////////////////////////
  //   {
  //     path: RouteName.Library.PrimaryLibrary,
  //     element: (
  //       <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
  //         <LibraryPrimary />
  //       </ProtectedRoute>
  //     ),
  //     exact: true,
  //   },

  //   {
  //     path: `${RouteName.Library.SubFolder}/:mainfoldername/:mainfolderid`,
  //     element: (
  //       <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}>
  //         <SubFolder />
  //       </ProtectedRoute>
  //     ),
  //     exact: true,
  //   },
];

export default AdminRoute;
