import React from "react";
import successImg from '../../Assets/images/Path 430.png'; 
import AdminRoute from "../../Route/RouteDetails";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";



const Success = () => {
  const navigate = useNavigate();

  const navigatetopage =()=>{
//   console.log("navigatetopage datapoint 1");

    navigate(`../${AdminRoute?.Common?.Aitherapist}`);
    
    
  }

    return (
        <div className="success-container">
            <div className="masge-card">
                <img src={successImg} />
                <p className="my-4 txt-center succes-lbl">
                    Payment Successful
                </p>
                <p className="my-3 txt-center info-lbl ">
                    Congratulations on completing your payment!
                    Explore your world with RYT Life’s AI therapist!
                </p>
              
                <button type="submit" className="button_common w-100 mt-3 mb-3" role="button"
                onClick={(e)=> navigatetopage(e)}
                >Start Your Session </button>

            </div>
        </div>
    )
}

export default Success;