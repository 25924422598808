// import React, { useEffect, useRef, useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import AdminRoute from "../../Route/RouteDetails";
// import { toast } from "react-toastify";
// // import 'react-toastify/dist/ReactToastify.css';
// import axios from "axios";
// import commoan from "../../environment/Config";

// const VerifyOtp = () => {
//   const navigate = useNavigate();
//   const InputType = JSON.parse(localStorage.getItem("InputType"));
//   const [otp, setOtp] = useState(["", "", "", ""]);
//   const [inType, setinType] = useState("");
//   const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
//   const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
//   const mobileRegex = /^\d+$/;
//   const [counter, setCounter] = useState(60);
//   const [masbableMobile, setmasbableMobile] = useState()

//   useEffect(() => {
//     if (emailRegex.test(InputType)) {
//       setinType("email");
//     }
//     if (mobileRegex.test(InputType)) {
//       setinType("mobile");
//     }
//   }, []);

//   const mobilefun = () => {
//     if (/^\d{10}$/.test(InputType)) {
//       var numberString = InputType.toString();
//       var lastFourDigits = numberString.slice(-4);
//       var maskedPart = 'X'.repeat(numberString.length - 4);
//       var maskedNumber = maskedPart + lastFourDigits;
//       setmasbableMobile(maskedNumber);
//     } 
//   };

//   useEffect(() => {
//     mobilefun();
//   }, []);

//   useEffect(() => {
//     const timeout = setTimeout(() => {
//       // Code to execute after 30 seconds
//     }, 30000);

//     const interval = setInterval(() => {
//       if (counter) {
//         setCounter((prevCounter) => prevCounter - 1);
//       }
//     }, 1000);

//     return () => {
//       clearTimeout(timeout);
//       clearInterval(interval);
//     };
//   }, [counter]);

//   function handleChange(event, index) {
//     const { value } = event.target;
//     setOtp((prevOtp) => {
//       const newOtp = [...prevOtp];
//       newOtp[index] = value;
//       return newOtp;
//     });

//     if (value.length === 1 && index < 3) {
//       inputRefs[index + 1].current.focus();
//     } else if (value.length === 0 && index > 0) {
//       if(event.key === 'Backspace'){
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   }

//   const handleKeyDown = (event, index) => {
//     const { value } = event.target;
//     if(event.key === 'Backspace'){
//       if (value.length === 0 && index > 0){
//         inputRefs[index - 1].current.focus();
//       }
//     }
//   } 

//   const newotp = otp.join("");

//   const handleVerifyOtp = (e) => {
//     e.preventDefault();

//     // if (inType === "mobile") {
//     //   try {
//     //     let data = JSON.stringify({
//     //       "phone": InputType,
//     //       "otp": newotp
//     //     });

//     //     let config = {
//     //       method: 'post',
//     //       maxBodyLength: Infinity,
//     //       url: `${common.baseurl}auth/login`,
//     //       headers: {
//     //         'Content-Type': 'application/json'
//     //       },
//     //       data: data
//     //     };

//     //     axios.request(config)
//     //       .then((response) => {
//     //         console.log(response.data);
//     //         if(response?.data?.message === "Login successful"){
//     //           localStorage.setItem("AuthTokenAI", JSON.stringify(response?.data?.data?.token));
//     //           navigate(`../${AdminRoute?.Common?.Aitherapist}`);
//     //         }else{
//     //           toast("error");
//     //           alert("error")
//     //         }

//     //       })
//     //   } catch (error) {
//     //     console.log(error);
//     //   };
//     // } if (inType === "email") {
//     //   try {
//     //     let data = JSON.stringify({
//     //       "email": InputType,
//     //       "otp": newotp
//     //     });

//     //     let config = {
//     //       method: 'post',
//     //       maxBodyLength: Infinity,
//     //       url: `${commoan.baseUrl}users/verify/otpmail`,
//     //       headers: {
//     //         'Content-Type': 'application/json'
//     //       },
//     //       data: data
//     //     };

//     //     axios.request(config)
//     //       .then((response) => {
//     //         console.log(response.data);
//     //         if(response?.data?.status === 1){
//     //           navigate(`../${AdminRoute?.Common?.Aitherapist}`);
//     //         }else{
//     //           toast("error");
//     //           alert("error")
//     //         }
//     //       })
//     //   } catch (error) {
//     //     console.log(error);
//     //   };
//     // }

//     try {
//       let data = JSON.stringify({
//         phone: InputType,
//         otp: newotp,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}auth/login`,
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
//         debugger;
//         console.log(response.data);
//         if (response?.data?.message === "Login successful") {
//           localStorage.setItem(
//             "AuthTokenAI",
//             JSON.stringify(response?.data?.data?.token)
//           );
//           navigate(`../${AdminRoute?.Common?.Aitherapist}`);
//           localStorage.setItem("isLoggedIn",true)

//           // toast.success(response?.data?.message);
//         } else {
//           toast.warning(response?.data?.message);
//         }
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // const handleResendOtp = (e) => {
//   //   e.preventDefault();

//   //   if (inType === "mobile") {
//   //     try {
//   //       let data = JSON.stringify({
//   //         "phone": inType
//   //       });

//   //       let config = {
//   //         method: 'post',
//   //         maxBodyLength: Infinity,
//   //         url: `${commoan.baseUrl}users/mresendotp`,
//   //         headers: {
//   //           'Content-Type': 'application/json'
//   //         },
//   //         data: data
//   //       };

//   //       axios.request(config)
//   //         .then((response) => {
//   //           console.log(response.data);
//   //           if (response?.data?.status === 1) {
//   //             // navigate(`../${AdminRoute?.Common?.Aitherapist}`);
//   //             setTimeout(() => {
//   //               setCounter(30);
//   //             }, 1000);
//   //           }
//   //         })
//   //     } catch (error) {
//   //       console.log(error);
//   //     };

//   //   } if (inType === "email") {
//   //     try {
//   //       let data = JSON.stringify({
//   //         "email": inType
//   //       });

//   //       let config = {
//   //         method: 'post',
//   //         maxBodyLength: Infinity,
//   //         url: `${commoan.baseUrl}users/resendotp`,
//   //         headers: {
//   //           'Content-Type': 'application/json'
//   //         },
//   //         data: data
//   //       };

//   //       axios.request(config)
//   //         .then((response) => {
//   //           console.log(response.data);
//   //           if (response?.data?.status === 1) {
//   //             // navigate(`../${AdminRoute?.Common?.Aitherapist}`);
//   //             setTimeout(() => {
//   //               setCounter(30);
//   //             }, 1000);
//   //           }
//   //         })
//   //     } catch (error) {
//   //       console.log(error);
//   //     };

//   //   }
//   // }

//   const handleResendOtp = (e) => {
//     e.preventDefault();

//     try {
//       let data = JSON.stringify({
//         phone: InputType,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}auth/otp`,
//         headers: {
//           "Content-Type": "application/json",
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
//         console.log(response?.data);
//         // if (response?.data?.user_registered === false) {
//         //   localStorage.setItem("InputType", PhoneNumber);
//         //   navigate(`../${AdminRoute?.Auth?.Register}`);
//         // } else {
//         //   localStorage.setItem("InputType", PhoneNumber);
//         //   navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
//         // }

//         if (response?.data?.message === "OTP resent") {
//           // toast.success(response?.data?.message);
//           setTimeout(() => {
//             setCounter(60);
//           }, 1000);
   
//         }
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <div className="Otp">
//       <div className="container login-page">
//         <div className="row main-container">
//           <div className="col-md-6 col-sm-12 left-text">
//             <div className="logo-div">
//               <NavLink to="/">
//                 <img src="/images/logo.png" className="logo" alt="logo" />{" "}
//               </NavLink>
//             </div>
//             <h1 className="heading">Your 24/7 companion for unbiased, affordable therapy—anytime, anywhere.</h1>
//             {/* <p className="subheading">Mental Health journey</p>
//             <p className="heading-discription">
//               through our online therapy programs, guided by our compassionate
//               team of psychologists.
//             </p> */}
//           </div>
//           {/* col-md-6 */}

//           <div className="col-md-6 col-sm-12">
//             <div className="otp-div">
//               <h2 className="heading">Just One More Tiny Step!</h2>
//               <p className="subheading">Please type in the OTP we’ve sent to your phone or email. Your new beginning is just a moment away.</p>
//               {inType === "email" ? (
//                 <p>
//                   * Enter 4 digit OTP sent on  "{InputType}" 
//                 </p>
//               ) : (
//                 <p>
//                   * Enter 4 digit OTP sent on "{masbableMobile}"
                  
//                 </p>
//               )}
//               {/* <p>* Enter 4 digit OTP sent on the registered “8XXXXXXXO mobile no.</p> */}

//               {/* <div className="otp-container text-center">
//               <input type="text" maxLength="1" className="otp-input" value="" />
              
//             </div> */}
//               <div style={{ justifyContent: "space-evenly", display: "flex" }}>
//                 {otp.map((value, index) => (
//                   <input
//                     key={index}
//                     type="number"
//                     id={`otp-${index}`}
//                     autoComplete="off"
//                     name={`otp-${index}`}
//                     value={value}
//                     onChange={(event) => handleChange(event, index)}
//                     onKeyDown={(event) => handleKeyDown(event, index)}
//                     maxLength={1}
//                     ref={inputRefs[index]}
//                     className="form-control custom-input-text-primary otp-input mb-0"
//                   />
//                 ))}
//               </div>
//               <button
//                 className="button_common"
//                 // to={AdminRoute?.Common?.Aitherapist}
//                 onClick={(e) => handleVerifyOtp(e)}
//               >
//                 {" "}
//                 Verify 
//               </button>

//               {/* <NavLink className="otp-text text-center" to="/ResendOtp"> Resend OTP</NavLink> */}

//               {counter === 0 ? (
//                 <p
//                   className="orange-small-link text-center cursor-pointer text-decoration-underline"
//                   // data-bs-target="#otpResentModal"
//                   // data-bs-toggle="modal"
//                   onClick={(e) => handleResendOtp(e)}
//                 >
//                   Resend OTP
//                 </p>
//               ) : (
//                 <h3 className="text-center text-color-secondary">
//                   {counter == 30
//                     ? `00:30`
//                     : counter >= 10
//                     ? `00:${counter}`
//                     : `00:0${counter}`}
//                 </h3>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VerifyOtp;

//=========================================================================//

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import commoan from "../../environment/Config";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const InputType = JSON.parse(localStorage.getItem("InputType"));
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [inType, setinType] = useState("");
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mobileRegex = /^\d+$/;
  const [counter, setCounter] = useState(60);
  const [masbableMobile, setmasbableMobile] = useState()

  useEffect(() => {
    if (emailRegex.test(InputType)) {
      setinType("email");
    }
    if (mobileRegex.test(InputType)) {
      setinType("mobile");
    }
  }, []);

  const mobilefun = () => {
    if (/^\d{10}$/.test(InputType)) {
      var numberString = InputType.toString();
      var lastFourDigits = numberString.slice(-4);
      var maskedPart = 'X'.repeat(numberString.length - 4);
      var maskedNumber = maskedPart + lastFourDigits;
      setmasbableMobile(maskedNumber);
    } 
  };

  useEffect(() => {
    mobilefun();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      // Code to execute after 30 seconds
    }, 30000);

    const interval = setInterval(() => {
      if (counter) {
        setCounter((prevCounter) => prevCounter - 1);
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [counter]);

  function handleChange(event, index) {
    const { value } = event.target;
    setOtp((prevOtp) => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value.length === 1 && index < 3) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      if(event.key === 'Backspace'){
        inputRefs[index - 1].current.focus();
      }
    }
  }

  const handleKeyDown = (event, index) => {
    const { value } = event.target;
    if(event.key === 'Backspace'){
      if (value.length === 0 && index > 0){
        inputRefs[index - 1].current.focus();
      }
    }
  } 

  const newotp = otp.join("");

  const handleVerifyOtp = (e) => {
    e.preventDefault();

    // if (inType === "mobile") {
    //   try {
    //     let data = JSON.stringify({
    //       "phone": InputType,
    //       "otp": newotp
    //     });

    //     let config = {
    //       method: 'post',
    //       maxBodyLength: Infinity,
    //       url: `${common.baseurl}auth/login`,
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       data: data
    //     };

    //     axios.request(config)
    //       .then((response) => {
    //         console.log(response.data);
    //         if(response?.data?.message === "Login successful"){
    //           localStorage.setItem("AuthTokenAI", JSON.stringify(response?.data?.data?.token));
    //           navigate(`../${AdminRoute?.Common?.Aitherapist}`);
    //         }else{
    //           toast("error");
    //           alert("error")
    //         }

    //       })
    //   } catch (error) {
    //     console.log(error);
    //   };
    // } if (inType === "email") {
    //   try {
    //     let data = JSON.stringify({
    //       "email": InputType,
    //       "otp": newotp
    //     });

    //     let config = {
    //       method: 'post',
    //       maxBodyLength: Infinity,
    //       url: `${commoan.baseUrl}users/verify/otpmail`,
    //       headers: {
    //         'Content-Type': 'application/json'
    //       },
    //       data: data
    //     };

    //     axios.request(config)
    //       .then((response) => {
    //         console.log(response.data);
    //         if(response?.data?.status === 1){
    //           navigate(`../${AdminRoute?.Common?.Aitherapist}`);
    //         }else{
    //           toast("error");
    //           alert("error")
    //         }
    //       })
    //   } catch (error) {
    //     console.log(error);
    //   };
    // }

    try {
      let data = JSON.stringify({
        phone: InputType,
        otp: newotp,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        debugger;
        console.log(response.data);
        if (response?.data?.message === "Login successful") {
          localStorage.setItem(
            "AuthTokenAI",
            JSON.stringify(response?.data?.data?.token)
          );
          navigate(`../${AdminRoute?.Common?.Aitherapist}`);
          localStorage.setItem("isLoggedIn",true)

          // toast.success(response?.data?.message);
        } else {
          toast.warning(response?.data?.message);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const handleResendOtp = (e) => {
  //   e.preventDefault();

  //   if (inType === "mobile") {
  //     try {
  //       let data = JSON.stringify({
  //         "phone": inType
  //       });

  //       let config = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: `${commoan.baseUrl}users/mresendotp`,
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         data: data
  //       };

  //       axios.request(config)
  //         .then((response) => {
  //           console.log(response.data);
  //           if (response?.data?.status === 1) {
  //             // navigate(`../${AdminRoute?.Common?.Aitherapist}`);
  //             setTimeout(() => {
  //               setCounter(30);
  //             }, 1000);
  //           }
  //         })
  //     } catch (error) {
  //       console.log(error);
  //     };

  //   } if (inType === "email") {
  //     try {
  //       let data = JSON.stringify({
  //         "email": inType
  //       });

  //       let config = {
  //         method: 'post',
  //         maxBodyLength: Infinity,
  //         url: `${commoan.baseUrl}users/resendotp`,
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         data: data
  //       };

  //       axios.request(config)
  //         .then((response) => {
  //           console.log(response.data);
  //           if (response?.data?.status === 1) {
  //             // navigate(`../${AdminRoute?.Common?.Aitherapist}`);
  //             setTimeout(() => {
  //               setCounter(30);
  //             }, 1000);
  //           }
  //         })
  //     } catch (error) {
  //       console.log(error);
  //     };

  //   }
  // }

  // const handleResendOtp = (e) => {
  //   e.preventDefault();

  //   try {
  //     let data = JSON.stringify({
  //       phone: InputType,
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${commoan.baseUrl}auth/otp`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios.request(config).then((response) => {
  //       console.log(response?.data);
  //       // if (response?.data?.user_registered === false) {
  //       //   localStorage.setItem("InputType", PhoneNumber);
  //       //   navigate(`../${AdminRoute?.Auth?.Register}`);
  //       // } else {
  //       //   localStorage.setItem("InputType", PhoneNumber);
  //       //   navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
  //       // }

  //       if (response?.data?.message === "OTP resent") {
  //         // toast.success(response?.data?.message);
  //         setTimeout(() => {
  //           setCounter(60);
  //         }, 1000);
   
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const resendOTP = () => {
    try {
      debugger;
      let data = JSON.stringify({
        phone: InputType,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}auth/otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        console.log(response?.data);
        // Handle response...
      });
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="Otp">
      <div className="container login-page">
        <div className="row main-container">
          <div className="col-md-6 col-sm-12 left-text">
            <div className="logo-div">
              <NavLink to="/">
                <img src="/images/logo.png" className="logo" alt="logo" />{" "}
              </NavLink>
            </div>
            <h1 className="heading">Your 24/7 companion for unbiased, affordable therapy—anytime, anywhere.</h1>
            {/* <p className="subheading">Mental Health journey</p>
            <p className="heading-discription">
              through our online therapy programs, guided by our compassionate
              team of psychologists.
            </p> */}
          </div>
          {/* col-md-6 */}

          <div className="col-md-6 col-sm-12">
            <div className="otp-div">
              <h2 className="heading">Just One More Tiny Step!</h2>
              <p className="subheading">Please type in the OTP we’ve sent to your phone or email. Your new beginning is just a moment away.</p>
              {inType === "email" ? (
                <p>
                  * Enter 4 digit OTP sent on  "{InputType}" 
                </p>
              ) : (
                <p>
                  * Enter 4 digit OTP sent on "{masbableMobile}"
                  
                </p>
              )}
              {/* <p>* Enter 4 digit OTP sent on the registered “8XXXXXXXO mobile no.</p> */}

              {/* <div className="otp-container text-center">
              <input type="text" maxLength="1" className="otp-input" value="" />
              
            </div> */}
              <div style={{ justifyContent: "space-evenly", display: "flex" }}>
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="number"
                    id={`otp-${index}`}
                    autoComplete="off"
                    name={`otp-${index}`}
                    value={value}
                    onChange={(event) => handleChange(event, index)}
                    onKeyDown={(event) => handleKeyDown(event, index)}
                    maxLength={1}
                    ref={inputRefs[index]}
                    className="form-control custom-input-text-primary otp-input mb-0"
                  />
                ))}
              </div>
              <button
                className="button_common"
                // to={AdminRoute?.Common?.Aitherapist}
                onClick={(e) => handleVerifyOtp(e)}
              >
                {" "}
                Verify 
              </button>

              {/* <NavLink className="otp-text text-center" to="/ResendOtp"> Resend OTP</NavLink> */}

                {/*{counter === 0 ? (
                <p
                  className="orange-small-link text-center cursor-pointer text-decoration-underline"
                  // data-bs-target="#otpResentModal"
                  // data-bs-toggle="modal"
                  onClick={(e) => handleResendOtp(e)}
                >
                  Resend OTP
                </p>
              ) : (
                <h3 className="text-center text-color-secondary">
                  {counter == 30
                    ? `00:30`
                    : counter >= 10
                    ? `00:${counter}`
                    : `00:0${counter}`}
                </h3>
              )}*/}
              <p
                  className="orange-small-link text-center cursor-pointer text-decoration-underline"
                  // data-bs-target="#otpResentModal"
                  // data-bs-toggle="modal"
                  onClick={(e) => resendOTP(e)}
                >
                  Resend OTP
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
