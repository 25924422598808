
// import React, { useState, useEffect } from "react";
// import { useNavigate, NavLink } from "react-router-dom";
// // import GoogleLogin from './../Firebase/firebase';
// import AdminRoute from "../../Route/RouteDetails";
// import axios from "axios";
// import commoan from "../../environment/Config";
// // import commoan from "../../environment/Config";
// // import PhoneInput from "react-phone-input-2";
// import { toast } from "react-toastify";
// import { Windows } from "react-bootstrap-icons";


// const Login = () => {
//   const navigate = useNavigate();
//   const [PhoneNumber, setPhoneNumber] = useState("");

//   const handleChange = (e) => {
//     setPhoneNumber(e.target.value);
//   }

//   useEffect(() => {
//     localStorage.clear()
//   }, [])
//   // const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
//   // const mobileRegex = /^\d+$/;

//   // const [formData, setFormData] = useState({
//   //   email: "",
//   // });

//   // const [error, setError] = useState("");

//   // const handleChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setFormData({
//   //     ...formData,
//   //     [name]: value,
//   //   });
//   // };

//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //   // API.
//   //   console.log(formData);

//   //   if (emailRegex.test(formData?.email)) {
//   //     try {
//   //       let data = JSON.stringify({
//   //         "email": formData?.email
//   //       });

//   //       let config = {
//   //         method: 'post',
//   //         maxBodyLength: Infinity,
//   //         url: `${commoan.baseUrl}users/resendotp`,
//   //         headers: {
//   //           'Content-Type': 'application/json'
//   //         },
//   //         data: data
//   //       };

//   //       axios.request(config)
//   //         .then((response) => {
//   //           console.log(response.data);
//   //           if (response?.data?.status === 1) {
//   //             alert(response?.data?.message);
//   //             localStorage.setItem("InputType", formData?.email);
//   //             navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
//   //           }
//   //         })
//   //     } catch (error) {
//   //       console.log(error);
//   //     };


//   //   } if (mobileRegex.test(formData?.email)) {

//   //     try {
//   //       let data = JSON.stringify({
//   //         "phone": formData?.email
//   //       });

//   //       let config = {
//   //         method: 'post',
//   //         maxBodyLength: Infinity,
//   //         url: `${commoan.baseUrl}/users/mresendotp`,
//   //         headers: {
//   //           'Content-Type': 'application/json'
//   //         },
//   //         data: data
//   //       };

//   //       axios.request(config)
//   //         .then((response) => {
//   //           console.log(response.data);
//   //           if (response?.data?.status === 1) {
//   //             alert(response?.data?.message);
//   //             localStorage.setItem("InputType", formData?.email);
//   //             navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
//   //           }
//   //         })
//   //     } catch (error) {
//   //       console.log(error);
//   //     };

//   //   }
//   //   // else {
//   //   //   setError("Enter Valid Mobile No or Email ID");
//   //   // }


//   // };

//   const [formErrors, setFormErrors] = useState({});
//   const [isSubmit, setIsSubmit] = useState(false);


//   const validate = (Phone) => {

//     const errors = {};
//     if (PhoneNumber === "") {
//       errors.Phone = "Mobile no is Required!";
//     }
//     else if (Phone?.length != 10) {
//       errors.Phone = "Enter Valid Mobile Number!";
//     }
//     return errors;
//   };

//   useEffect(() => {

//     if (Object.keys(formErrors).length === 0 && isSubmit) {
//       try {
//         let data = JSON.stringify({
//           "phone": PhoneNumber
//         });

//         let config = {
//           method: 'post',
//           maxBodyLength: Infinity,
//           url: `${commoan.baseUrl}auth/otp`,
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           data: data
//         };

//         axios.request(config)
//           .then((response) => {
//             console.log(response.data);
//             if (response?.data?.user_registered === false) {
//               localStorage.setItem("InputType", PhoneNumber);
//               navigate(`../${AdminRoute?.Auth?.Register}`);
//             } else {
             
//               navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
//               localStorage.setItem("InputType", PhoneNumber);
//             }
//           })
//       } catch (error) {
//         console.log(error);
//       };

//     }

//   }, [formErrors])




//   const handleSubmitMobile = (e) => {
//     e.preventDefault();
//     console.log(PhoneNumber == "" ? "empty" : PhoneNumber)
//     setFormErrors(validate(PhoneNumber));
//     setIsSubmit(true);
//   }

//   return (
//     <div className="container login-page">
//       <div className="row main-container">
//         <div className="col-md-6 col-sm-12 left-text">
//           <div className="logo-div">
//             <NavLink to="/"><img src='/images/logo.png' className="logo" alt="logo" /> </NavLink>
//           </div>
//           <h1 className="heading">Your 24/7 companion for unbiased, affordable therapy—anytime, anywhere.</h1>
//           <p className="heading-discription">Sign back into your space, your haven. We’re here to continue supporting your journey to happiness and self-understanding.</p>
//          {/* <p className="subheading">Sign back into your space, your haven. We’re here to continue supporting your journey to happiness and self-understanding.</p> */}
//            {/* <p className="heading-discription">Through our online therapy programs, guided by our compassionate team of psychologists.</p> */}
//         </div>{/* col-md-6 */}

//         <div className="col-md-6 col-sm-12">
//           <div className="register">
//             <form >
//               <h2 className="heading">Welcome back</h2>
//               {/* <p className="heading-discription">Sign back into your space, your haven. We’re here to continue supporting your journey to happiness and self-understanding.</p> */}
//               <div>
//                 <label>Enter Mobile No</label>
//                 <input
//                   type="text"
//                   name="number"
//                   autoComplete="off"
//                   // placeholder="E-Mail ID"
//                   // defaultValue={formData.email}
//                   onChange={(e) => handleChange(e)}
//                   maxLength="10" 
//                 // required
//                 />
//               </div>

//               {/* <div className="ph-input">
//                 <PhoneInput
//                   // country={"us"}
//                   placeholder="Enter Phone Number"
//                   onChange={value => setPhoneNumber(value)}
//                 />
//               </div> */}

//               <p className='text-danger'>{formErrors?.Phone}</p>

//               <button type="submit" className="button_common w-100 mt-3 mb-3" role="button"
//                 onClick={(e) => handleSubmitMobile(e)}>Send OTP</button>

//               {/* <div className="form-group">
//                 <div className="custom-control custom-checkbox">
//                   <input type="checkbox" className="custom-control-input" id="customCheck1" />
//                   <label className="custom-control-label" htmlFor="customCheck1">Stay logged in </label>
//                 </div>
//               </div> */}

//               {/* <h6 className="or-text text-center">OR</h6>
//               <div className="stage">
//                 <div>
//                   <GoogleLogin />
//                 </div>

//               </div>
//               <p className="account-text">Don't have an account?</p>

//               <NavLink className="register-text" to={AdminRoute?.Auth?.Register}>Let's Register </NavLink> */}

//             </form>
//           </div>
//         </div>{/* col-md-6 */}
//       </div >
//     </div >
//   );
// };
// export default Login;

//==========================================================================================//
import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import axios from "axios";
import commoan from "../../environment/Config";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const validate = (phone) => {
    const errors = {};
    if (!phone) {
      errors.phone = "Mobile number is required!";
    } else if (!/^[6-9]\d{9}$/.test(phone)) {
      errors.phone = "Invalid mobile number";
    }
    return errors;
  };

  const handleSubmitMobile = (e) => {
    e.preventDefault();
    setFormErrors(validate(phoneNumber));
    setIsSubmit(true);
  };


  useEffect(() => {

    if (Object.keys(formErrors).length === 0 && isSubmit) {
      try {
        let data = JSON.stringify({
          "phone":phoneNumber
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${commoan.baseUrl}auth/otp`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        axios.request(config)
          .then((response) => {
            // console.log(response.data);
            if (response?.data?.user_registered === false) {
              localStorage.setItem("InputType", phoneNumber);
              navigate(`../${AdminRoute?.Auth?.Register}`);
            } else {
             
              navigate(`../${AdminRoute?.Auth?.VerifyOtp}`);
              localStorage.setItem("InputType", phoneNumber);
            }
          })
      } catch (error) {
        console.log(error);
      };

    }

  }, [formErrors])




  // const handleSubmitMobile = (e) => {
  //   e.preventDefault();
  //   console.log(PhoneNumber == "" ? "empty" : PhoneNumber)
  //   setFormErrors(validate(PhoneNumber));
  //   setIsSubmit(true);
  // }

  return (
        <div className="container login-page">
      <div className="row main-container">
        <div className="col-md-6 col-sm-12 left-text">
          <div className="logo-div">
            <NavLink to="/"><img src='/images/logo.png' className="logo" alt="logo" /> </NavLink>
          </div>
          <h1 className="heading">Your 24x7 Wellness Companion for</h1>
          <h1 className="heading">Unbiased Support.</h1>
          <p className="heading-discription">Sign back into your safe space. We’re here to continue supporting your journey to happiness and self-understanding.</p>
         {/* <p className="subheading">Sign back into your space, your haven. We’re here to continue supporting your journey to happiness and self-understanding.</p> */}
           {/* <p className="heading-discription">Through our online therapy programs, guided by our compassionate team of psychologists.</p> */}
        </div>{/* col-md-6 */}
        <div className="col-md-6 col-sm-12">
          <div className="register">
            <form>
              <h2 className="heading">Login</h2>
              <div>
                <label>Enter Mobile No</label>
                <input
                  type="text"
                  name="number"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  maxLength="10"
                />
              </div>
              <p className='text-danger'>{formErrors?.phone}</p>
              <button
                type="submit"
                className="button_common w-100 mt-3 mb-3"
                onClick={(e) => handleSubmitMobile(e)}
              >
                Send OTP
              </button>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
