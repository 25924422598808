import React, { useEffect, useState } from "react";
import commoan from "../../environment/Config";
import axios from "axios";
import moment from "moment";
import backBtn from "../../Assets/images/Icon ionic-ios-arrow-back.png";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";


const CommonTable = () => {

    const authToken = JSON.parse(localStorage.getItem("AuthTokenAI"));
    const [PlanHistoryList, setPlanHistoryList] = useState("");
    const naviagate = useNavigate();
    const onBackClick = () => {
        naviagate(-1)
        // naviagate(AdminRoute?.Common?.Aitherapist);
    };


    // const getPlanHistory = () => {

    // }



    useEffect(() => {
        let data = '';

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${commoan.baseUrl}subscription/getBillingHistory`,
            headers: {
                'authorization': authToken,
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log("planss", response.data);
                setPlanHistoryList(response?.data?.newSubscriptions
                )
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // const data = [
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    //     { orderId: "XX", feature: 'Basic', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "6/11/2023" },
    // ]
    return (
        <div>
            <span onClick={() => onBackClick()}>
                <img
                    src={backBtn}
                    style={{
                        height: "30px",
                        height: "23px",
                        marginLeft: "16px",
                        opacity: "0.7",
                    }}

                />

                <label className="bck-lbl">Back to Home</label>
            </span>

            <div className="table-container">
                <table className="common-table" cellSpacing="3">

                    <tr className="table-head">
                        <th>Invoice Id</th>
                        <th>Plan</th>
                        <th>Price</th>
                        <th>Payment Date</th>
                        <th>Expiry Date</th>
                    </tr>
                    {PlanHistoryList?.length && PlanHistoryList?.map((val, key) => {
                        return (
                            <tr key={key} className="table-deff">
                                <td>{val?.invoice_id}</td>
                                <td>{val?.plan_name}</td>
                                <td>{val?.amount}</td>
                                <td>{moment(val?.created_at).format("DD MMM YYYY")}</td>
                                <td>{moment(val?.expiry).format("DD MMM YYYY")}</td>
                            </tr>
                        )
                    })}
                </table>
            </div>
        </div>

    )
}

export default CommonTable;