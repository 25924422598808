// // eslint-disable
// import React, { useEffect, useState, useRef, useReducer } from "react";
// import Button from "react-bootstrap/Button";
// import ReactScrollableFeed from 'react-scrollable-feed';
// import axios from "axios";
// import { toast } from "react-toastify";
// import {
//   useNavigate,
//   useLocation,
//   NavLink,
// } from "react-router-dom";
// import AdminRoute from "../../Route/RouteDetails";
// import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
// import commoan from "../../environment/Config";
// import moment from "moment";
// import Popup from "reactjs-popup";
// import "reactjs-popup/dist/index.css";
// import power from "../../Assets/images/power.png";
// import adam from "../../Assets/images/adam.png";
// import RouteName from "../../Route/RouteDetails";

// const Aitherapist = ({ messages }) => {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const megID = queryParams.get("id");
//   const [currentChatId, setcurrentChatId] = useState("");
//   const [isRecording, setIsRecording] = useState(false);
//   const [getSUb_status, setgetSUb_status] = useState();
//   const navigate = useNavigate();
//   const authToken = JSON.parse(localStorage.getItem("AuthTokenAI"));
//   const mobile = localStorage.getItem("InputType");
//   const [AIChat, setAIChat] = useState([]);
//   const [AiInputChat, setAiInputChat] = useState("");
//   const [AiOutput, setAiOutput] = useState([]);
//   // const [AllAiChat, setAllAiChat] = useState([]);
//   const [AIConversation, setAIConversation] = useState([]);
//   const [isGenerating, setisGenerating] = useState(false);
//   const [useCase, setUseCase] = useState(2);
//   const [chatHistory, setChatHistory] = useState([]);
//   const [Available_Time_Minutes, setAvailable_Time_Minutes] = useState();
//   const [Available_Time_Seconds, setAvailable_Time_Seconds] = useState();
//   const [rows, setRows] = useState(1);
//   const [time, setTime] = useState(0);
//   const [showPrevChat, setShowPrevChat] = useState(false);
//   const [showpayment, setShowpayment] = useState(false);
//   const [showtherapist, setShowtherapist] = useState(false);
//   const [showMessageBox, setShowMessageBox] = useState(true);
//   const [response, setResponse] = useState(null);
//   const audioRef = useRef(null);

//   const textareaRef = useRef(null);

//   const scrollableDivRef = useRef(null);

//   const onRecord = (recState) => {
//     setIsRecording(!recState);
//   };

//   const scrollToBottom = () => {
//     // console.log(scrollableDivRef);
//     if (scrollableDivRef.current) {
//       // scrollableDivRef.current.scrollTo =
//       //   scrollableDivRef.current.scrollHeight;
//       // setScrollht(scrollableDivRef.current.scrollHeight);
//       // scrollableDivRef.current.scrollIntoView({ behavior: 'smooth' })
//     }
//   };

//   const messageEndRef = useRef(null);

//   // useEffect(() => {
//   //   if (messageEndRef.current) {
//   //     messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
//   //   }

//   // }, [AIConversation]);

//   const [isOpen, setIsOpen] = useState(false);
//   const handleOnClick = (st) => {
//     setIsOpen(st);
//   }



//   const scrollToLastMessage = () => {
//     // messageEndRef.current.scrollIntoView({ behaviour: 'smooth' })
//   }

//   useEffect(() => {

//     scrollToLastMessage();
//   }, [AIConversation])

//   function addAIMessage(msg) {
//     // setAllAiChat([...AllAiChat, AiInputChat]);
//     console.log("addAIMessage  : being called")
//     let timer = setTimeout(() => setisGenerating(true), 1e1);

//     AIConversation?.length == 0
//       ? setAIConversation((prevArray) => [
//         ...prevArray,
//         {
//           content: AIChat?.output?.content,
//           created_at: "2023-10-23T08:02:07.770Z",
//           role: "assistant",
//         },
//         {
//           content: msg,
//           created_at: "2023-10-23T08:02:07.770Z",
//           role: "user",
//         },
//       ])
//       : setAIConversation((prevArray) => [
//         ...prevArray,
//         {
//           content: msg,
//           created_at: "2023-10-23T08:02:07.770Z",
//           role: "user",
//         },
//       ]);

//     try {
//       let data = JSON.stringify({
//         use_case_id: useCase,
//         content: msg,
//         message_id: megID ? megID : AIChat?.message_id,
//         phone: mobile,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}input_chat`,
//         headers: {
//           Authorization: authToken,
//           "Content-Type": "application/json",
//         },
//         data: data,
//       };
//       debugger
//       axios.request(config).then((response) => {
//         if (response?.data?.message === 'Subscription not found' || response?.data?.message === 'Subscription not active' || response?.data?.message === 'Subscription expired' || response?.data?.message === 'Seconds exhausted') {

//           navigate(`../${AdminRoute?.Common?.Plan}/1`);
//         }
//         // console.log("setAiOutput", response?.data?.data);
//         if (response?.data?.result === "success") {
//           setAiOutput(response?.data?.data);
//           console.log("response data of 1 input_chat ", response);
//           setResponse(response?.data?.data);
//           setAiInputChat("");
//           setisGenerating(false);
//           clearTimeout(timer);
//           setAvailable_Time_Minutes(
//             Math.floor(response?.data?.available_time / 60)
//           );
//           setAvailable_Time_Seconds(response?.data?.available_time % 60);
//           let plan_time = getSUb_status?.plan[0]?.seconds
//           let subscription_time = response?.data?.data?.available_time
//           console.log("plan_time", plan_time);
//           console.log("subscription_time", subscription_time);
//           console.log("time", (Math.abs(plan_time - subscription_time) / 60));
//           setTime(Math.abs(plan_time - subscription_time));
//           navigate(
//             `../${AdminRoute?.Common?.Aitherapist}?id=${response?.data?.data?.message_id}`,
//             { replace: true }
//           );
//           // AllAiChat = [...AllAiChat, response?.data?.data?.message];
//           // setAllAiChat([...AllAiChat, response?.data?.data?.message]);

//           // =============================
//           try {
//             const qs = require("qs");
//             let data = qs.stringify({
//               message_id: megID ? megID : AIChat?.message_id,
//             });

//             let config = {
//               method: "post",
//               maxBodyLength: Infinity,
//               url: `${commoan.baseUrl}getConversation`,
//               headers: {
//                 authorization: authToken,
//               },
//               data: data,
//             };

//             axios.request(config).then((response) => {
//               // console.log(response?.data);
//               setAIConversation(response?.data?.data?.message);
//             });
//           } catch (error) {
//             // console.log(error);
//           }
//         }
//       });
//     } catch (error) {
//       // console.log(error);
//     }
//     scrollToBottom();
//   }

//   const sendBinaryDataToBackend = async (blob) => {
//     const data = new FormData();

//     data.append("file", blob, "output.mp3");

//     // console.log(data);

//     axios
//       .post(`${commoan.baseUrl}transcribe`, data, {
//         headers: {
//           Authorization: authToken,
//           // Remove "Content-Type" from here, Axios will set it automatically for FormData
//         },
//       })
//       .then((response) => {
//         // console.log(response.data.transcription);
//         addAIMessage(response.data?.transcription);
//         // setAllAiChat([...AllAiChat, response?.data?.output]);
//       })
//       .catch(function (error) {
//         console.log(error);
//         toast.error(error?.data?.message);
//       });
//   };

//   //audio  recording functionality
//   const toggleUseCase = () => {
//     if (useCase === 2) {
//       setUseCase(4);
//     } else {
//       setUseCase(2);
//     }
//   };
//   const recorderControls = useAudioRecorder(
//     {
//       noiseSuppression: true,
//       echoCancellation: true,
//     },
//     (err) => console.table(err) // onNotAllowedOrFound
//   );
//   const addAudioElement = (blob) => {
//     sendBinaryDataToBackend(blob);
//     // const url = URL.createObjectURL(blob);
//     // const audio = document.createElement("audio");
//     // audio.src = url;
//     // audio.controls = true;
//     // document.body.appendChild(audio);
//   };


//   const theme = {
//     "--bg-color": useCase == "4" ? "#db95db" : "#3857b3",
//   };
//   // =====================api call for grt user info =================================

//   useEffect(() => {
//     if (megID) {
//       getMessages(megID, 'old');
//     }

//     HistoryConversation();
//   }, []);

//   // =====================api call for first input chat  =================================

//   const HistoryConversation = () => {
//     console.log("HistoryConversation  : being called")
//     try {
//       let config = {
//         method: "get",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}getConversationHistory`,
//         headers: {
//           Authorization: authToken,
//         },
//       };

//       axios.request(config).then((response) => {
//         setChatHistory(response?.data?.data);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     debugger
//     if (!megID) {
//       console.log("useEffect 1  : being called")
//       const initailChat = () => {
//         try {
//           let data = {
//             use_case_id: useCase,
//             content: "",
//             phone: mobile,
//           };

//           let config = {
//             method: "post",
//             maxBodyLength: Infinity,
//             url: `${commoan.baseUrl}input_chat`,
//             headers: {
//               Authorization: authToken,
//             },
//             data: data,
//           };

//           axios.request(config).then((response) => {
//             if (response?.data?.message === 'Subscription not found' || response?.data?.message === 'Subscription not active' || response?.data?.message === 'Subscription expired' || response?.data?.message === 'Seconds exhausted') {

//               navigate(`../${AdminRoute?.Common?.Plan}/1`);
//             }
//             console.log("response data of 2 input_chat ", response);
//             setResponse(response?.data);
//             setAIChat(response?.data);
//             setcurrentChatId(response?.data?.message_id);

//             setAvailable_Time_Minutes(
//               Math.floor(response?.data?.available_time / 60)
//             );
//             setAvailable_Time_Seconds(response?.data?.available_time % 60);
//             navigate(
//               `../${AdminRoute?.Common?.Aitherapist}?id=${response?.data?.message_id}`,
//               { replace: true }
//             );

//             if (response?.data?.error_code === "TRIAL_EXPIRED") {
//               toast.error(response?.data?.message);

//             }
//           });
//         } catch (error) {
//           toast.error(error?.data?.message);
//           if (error?.data?.message === 'Subscription not found' || error?.data?.message === 'Subscription not active' || error?.data?.message === 'Subscription expired' || error?.data?.message === 'Seconds exhausted') {
//             navigate(`../${AdminRoute?.Common?.Plan}`);
//           }
//         }
//       }

//       initailChat();
//     }
//   }, []);



//   const handleChange = (e) => {
//     setAiInputChat(e.target.value);
//   };

//   useEffect(() => {
//     debugger
//     const textarea = textareaRef.current;
//     if (rows < 5) {
//       setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
//     }
//     if (AiInputChat.length === 0) {
//       setRows(1);
//     }

//     // if (textarea.clientHeight < textarea.scrollHeight) {
//     //   setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
//     // }else if(textarea.clientHeight > textarea.scrollHeight){
//     //   setRows(Math.max(1, Math.floor(textarea.srollHeight / 30)));
//     // }
//   }, [AiInputChat]);

//   // =====================api call for chat section =================================

//   const handleSubmitChat = (e) => {
//     setRows(1);
//     e.preventDefault();
//     console.log("handleSubmitChat  : being called")
//     if (AiInputChat != "") {
//       AIConversation?.length == 0
//         ? setAIConversation((prevArray) => [
//           ...prevArray,
//           {
//             content: AIChat?.output?.content,
//             created_at: "2023-10-23T08:02:07.770Z",
//             role: "assistant",
//           },
//           {
//             content: AiInputChat,
//             created_at: "2023-10-23T08:02:07.770Z",
//             role: "user",
//           },
//         ])
//         : setAIConversation((prevArray) => [
//           ...prevArray,
//           {
//             content: AiInputChat,
//             created_at: "2023-10-23T08:02:07.770Z",
//             role: "user",
//           },
//         ]);

//       let timer = setTimeout(() => setisGenerating(true), 1e1);
//       try {
//         let data = JSON.stringify({
//           use_case_id: useCase,
//           content: AiInputChat,
//           message_id: megID ? megID : AIChat?.message_id,
//           phone: mobile,
//         });

//         let config = {
//           method: "post",
//           maxBodyLength: Infinity,
//           url: `${commoan.baseUrl}input_chat`,
//           headers: {
//             Authorization: authToken,
//             "Content-Type": "application/json",
//           },
//           data: data,
//         };

//         axios.request(config).then((response) => {
//           if (response?.data?.message === 'Subscription not found' || response?.data?.message === 'Subscription not active' || response?.data?.message === 'Subscription expired' || response?.data?.message === 'Seconds exhausted') {
//             navigate(`../${AdminRoute?.Common?.Plan}/1`);
//           }
//           if (response?.data?.result === "success") {
//             console.log("response data of 3 input_chat ", response);
//             setResponse(response?.data?.data);
//             setAiInputChat("");
//             console.log(response?.data?.data.message_id);
//             navigate(
//               `../${AdminRoute?.Common?.Aitherapist}?id=${response?.data?.data.message_id}`,
//               { replace: true }
//             );
//             // }
//             setAIConversation((prevArray) => [
//               ...prevArray,
//               {
//                 content: response?.data?.data?.message,
//                 created_at: "2023-10-23T08:02:07.770Z",
//                 role: "assistant",
//               },
//             ]);
//             setisGenerating(false);
//             clearTimeout(timer);
//             setAvailable_Time_Minutes(
//               Math.floor(response?.data?.data?.available_time / 60)
//             );
//             setAvailable_Time_Seconds(response?.data?.data?.available_time % 60);
//             let plan_time = getSUb_status?.plan[0]?.seconds
//             let subscription_time = response?.data?.data?.available_time
//             console.log("plan_time", plan_time);
//             console.log("subscription_time", subscription_time);
//             console.log("time", (Math.abs(plan_time - subscription_time) / 60));
//             setTime(Math.abs(plan_time - subscription_time));
//             // =============================
//             // try {
//             // 	const qs = require("qs");
//             // 	let data = qs.stringify({
//             // 		message_id: AIChat?.message_id,
//             // 	});

//             // 	let config = {
//             // 		method: "post",
//             // 		maxBodyLength: Infinity,
//             // 		url: `${commoan.baseUrl}getConversation`,
//             // 		headers: {
//             // 			authorization: authToken,
//             // 		},
//             // 		data: data,
//             // 	};

//             // 	axios.request(config).then((response) => {
//             // 		console.log(response?.data);
//             // 		setAIConversation(response?.data?.data?.message);

//             // 		// prune api call

//             // 		// try {
//             // 		// 	let data = "";

//             // 		// 	let config = {
//             // 		// 		method: "post",
//             // 		// 		maxBodyLength: Infinity,
//             // 		// 		url: `${commoan.baseUrl}prune`,
//             // 		// 		headers: {
//             // 		// 			authorization: authToken,
//             // 		// 		},
//             // 		// 		data: data,
//             // 		// 	};

//             // 		// 	axios.request(config).then((response) => {
//             // 		// 		console.log(response?.data);
//             // 		// 	});
//             // 		// } catch (error) {
//             // 		// 	console.log(error);
//             // 		// }
//             // 	});
//             // } catch (error) {
//             // 	console.log(error);
//             // }
//           } else {
//             toast.error(response?.data?.message);
//           }
//         });
//       } catch (error) {
//         console.log(error);
//       }
//     } else toast.error("Input text must not be empty");
//     scrollToBottom();
//   };

//   //   ========Get conversation api =========
//   // console.log("useCase", useCase);
//   const getMessages = (conversation, check) => {
//     try {
//       const qs = require("qs");
//       let data = qs.stringify({
//         message_id: check ? conversation : conversation?.message_id,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}getConversation`,
//         headers: {
//           authorization: authToken,
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//         data: data,
//       };
//       axios.request(config).then((response) => {
//         setAIConversation(response?.data?.data?.message);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   // =================== input chat section api call end=================

//   //side Effect

//   useEffect(() => {
//     debugger
//     scrollToBottom();
//   }, [isGenerating]);


//   const handleLogout = (e) => {
//     e.preventDefault();
//     let data = "";
//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `${commoan.baseUrl}logout`,
//       headers: {
//         Authorization: authToken,
//       },
//       data: data,
//     };

//     axios.request(config).then((response) => {
//       if (response?.data?.result === "success") {
//         navigate(`../${AdminRoute?.Auth?.Login}`);
//       }
//     })
//       .catch((error) => {
//         if (error.response.status === 401) {
//           navigate(`../${AdminRoute?.Auth?.Login}`);

//         }
//       })
//   }

//   //enter input text function
//   const handleKeyPress = (e) => {
//     if (e.key === "Enter" && !e.shiftKey) {
//       setRows(1);
//       handleSubmitChat(e);
//       setAiInputChat("");
//       e.preventDefault();
//     } else if (e.key === "Enter" && e.shiftKey) {
//       if (rows < 6) {
//         setRows(rows + 1);
//       }
//       const cursorPosition = e.target.selectionStart;
//       const newInputValue =
//         AiInputChat.substring(0, cursorPosition) +
//         "\n" +
//         AiInputChat.substring(cursorPosition);
//       setAiInputChat(newInputValue);
//       e.preventDefault();
//     }
//   };

//   // delete conversatation api
//   const getDeleteconversation = (e, id) => {
//     try {
//       const qs = require("qs");
//       let data = qs.stringify({
//         message_id: id?.message_id,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}deleteConversation`,
//         headers: {
//           authorization: authToken,
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
//         HistoryConversation();
//         // toast.success("Chat Deleted");
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   // ===========Current Chat ========

//   const handleCurrentChat = (id) => {
//     try {
//       const qs = require("qs");
//       let data = qs.stringify({
//         message_id: id,
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}getConversation`,
//         headers: {
//           authorization: authToken,
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
//         console.log("audio check", response?.data?.data?.message)
//         setAIConversation(response?.data?.data?.message);
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };



//   //Time and seconnds

//   useEffect(() => {
//     debugger
//     let config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `${commoan?.baseUrl}subscription/getSubscriptionStatus`,
//       headers: {
//         'authorization': authToken
//       }
//     };
//     axios.request(config)
//       .then((response) => {
//         if (response.data.success) {
//           console.log("++++++++++++++", response.data.plan[0].seconds);
//           console.log("==============", response.data.subscription.seconds);
//           setTime(Math.abs(response.data.plan[0].seconds - response.data.subscription.seconds));
//           setgetSUb_status(response.data);
//           setUseCase(response.data.subscription.use_case_id)
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, []);



//   useEffect(() => {
//     debugger
//     let plan_time = getSUb_status?.plan[0]?.seconds
//     let subscription_time = getSUb_status?.subscription?.seconds
//     setTime(Math.abs(plan_time - subscription_time));
//   }, [])



//   const resetAudio = () => {
//     if (audioRef.current) {
//       audioRef.current.src = '';
//     }
//   };


//   // useEffect(() => {
//   //   // Listen to the 'ended' event of the audio element
//   //   const handleAudioEnded = () => {
//   //     // Reset audio when playback ends
//   //     resetAudio();
//   //   };

//   //   if (audioRef.current) {
//   //     audioRef.current.addEventListener('ended', handleAudioEnded);

//   //     // Cleanup function to remove event listener
//   //     return () => {
//   //       audioRef.current.removeEventListener('ended', handleAudioEnded);
//   //     };
//   //   }
//   // }, []);

//   useEffect(() => {
//     debugger
//     // Listen to the 'ended' event of the audio element
//     const handleAudioEnded = () => {
//       // Reset audio when playback ends
//       resetAudio();
//     };

//     const audioElement = audioRef.current;

//     if (audioElement) {
//       audioElement.addEventListener('ended', handleAudioEnded);

//       // Cleanup function to remove event listener
//       return () => {
//         audioElement.removeEventListener('ended', handleAudioEnded);
//       };
//     }
//   }, [audioRef]);

//   useEffect(() => {
//     if (response && response.buffer) {
//       // Play audio when buffer data is received
//       if (audioRef.current) {
//         audioRef.current.src = `data:audio/mp3;base64,${response.buffer}`;
//         audioRef.current.play();
//       }
//     }
//   }, [response]);


//   axios.interceptors.response.use((response) => {
//     return response;
//   }, (error) => { // Anything except 2XX goes to here
//     const status = error.response?.status || 500;
//     if (status === 401) {
//       window.location = window.location.protocol + "//" + window.location.host + "/#/Login"
//     } else {
//       return Promise.reject(error); // Delegate error to calling side
//     }
//   });
//   useEffect(() => {
//     console.log("useEffect time :", time);

//   }, [time]);

//   const handleAdamClick = () => {

//     // Make API call
//     try {
     
//         debugger
//         console.log("getSUb_status :",getSUb_status)
//       let data = JSON.stringify({
//         // user_id: getSUb_status?.subscription?.user_id,
//         use_case_id:  4 ,
        
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}changeUsecase`,
//         headers: {
//           Authorization: authToken,
//           "Content-Type": "application/json",
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
        
//         if(response?.data){
//           console.log("response data for handleTherapistClick ", response);
         
          
         
//         } else {
//           toast.error(response?.data?.message);
//         }
//       });
//       setUseCase(4);
//    }catch (error) {
//       console.log(error);
//     }
//   };

//   const handleEveClick = () => {

//     // Make API call
//     try {
     
//         debugger
//         console.log("getSUb_status :",getSUb_status)
//       let data = JSON.stringify({
//         // user_id: getSUb_status?.subscription?.user_id,
//         use_case_id:  2,
        
//       });

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${commoan.baseUrl}changeUsecase`,
//         headers: {
//           Authorization: authToken,
//           "Content-Type": "application/json",
//         },
//         data: data,
//       };

//       axios.request(config).then((response) => {
        
//         if(response?.data){
//           console.log("response data for handleTherapistClick ", response);
         
          
         
//         } else {
//           toast.error(response?.data?.message);
//         }
//       });
//       setUseCase(2);
//    }catch (error) {
//       console.log(error);
//     }
//   };

//   //jsx
//   return (
//     <div className="d-flex h-dashboard">
//       <div className="toggleIcon aitherapist">
//         <div className="container-fluid">
//           <div className="row">
//             {/* <div className="row">
//               <nav className="navbar navbar-expand-lg header-navbar p-0">
//                 <h2 className="heading  mt-1 mb-1">Al Therapist</h2>
//                 <Header handleLogout={handleLogout} UserInfo={UserInfo} />
//               </nav>
//             </div> */}
//             {/* <div className="row"> */}
//             {/* Added Class for dark theme and light theme */}
//             <div
//               className={
//                 "ai-div " + (useCase === 2 ? " dark-theme" : "dark-theme")
//               }
//             // style={{
//             //   backgroundImage:
//             //     "url(" +
//             //     (useCase == 4
//             //       ? AdamTheme
//             //       : "https://i.pinimg.com/564x/ec/5c/d9/ec5cd9f0428c94b39a271f0d73fa5e50.jpg") +
//             //     ")",
//             // }}
//             >
//               <div className="ai-div__inner">
//                 <div className="switches-div d-flex">
//                   <div>
//                     <div className="icon-div">
//                       <img src={useCase === 2 ? power : adam} className="User" alt="icon" />
//                     </div>
//                     {useCase === 2 ?<span className="toggle-label">Eve</span> : <span className="toggle-label">Adam</span> }
//                   </div>
//                   {/* <Form className="float-left">
//                       <label className="toggle">
//                         <span className="toggle-label mr-2">Adam</span>
//                         <input
//                           className="toggle-checkbox"
//                           type="checkbox"
//                           checked={useCase == 4 ? false : true}
//                           onChange={toggleUseCase}
//                         />
//                         <div className="toggle-switch" style={theme}></div>
//                         <span className="toggle-label">Eve</span>
//                       </label>
//                     </Form> */}

//                   <div className="Avail-Time">
//                     <div className="Avail-Toggle">
//                       <label className="switch">
//                         <input type="checkbox" id="togBtn" />

//                         {/* <div 
//                               className="slider round"
//                               onClick={() => {
//                                 ShowTime === false
//                                   ? setShowTime(true)
//                                   : setShowTime(false);
//                               }}
//                             >
//                               <span className="on">Available Time</span>
//                               <span className="off time">
//                                 {Available_Time_Minutes}:
//                                 {Available_Time_Seconds}
//                               </span>
//                             </div> */}
//                       </label>
//                     </div>
//                   </div>

//                   <span className="Avail-Time-Text">

//                     {time === 0 || isNaN(time) ? <></> : (
//                       <span>
//                         {Math.floor(time / 3600) !== 0 ? (
//                           <span>{Math.floor(time / 3600)} hr  {Math.floor(time % 3600 / 60)} min</span>
//                         ) : (
//                           <span>{Math.floor(time % 3600 / 60)} min</span>
//                         )}
//                       </span>
//                     )}

//                     {/* {Math.floor(time/3600) !== 0 ? (
//                       <span>{Math.floor(time / 3600)} : {Math.floor(time % 3600 / 60)}</span>
//                       ) : (
//                         <span>{Math.floor(time % 3600 / 60)}</span>
//                       )} */}

//                   </span>

//                   {/* {Available_time ? (
//                       <>
//                         {" "}
//                         <span>Available time:{Available_time} minutes</span>
//                       </>
//                     ) : (
//                       <></>
//                     )} */}

//                   <div className="d-flex">
//                     <ul className="dropbtn icons mb-0" onClick={() => handleOnClick(true)}>
//                       <li></li>
//                       <li></li>
//                       <li></li>
//                     </ul>
//                     <Popup
//                       className="chat-histroy-pop-up "
//                       open={isOpen}
//                       onClose={() => setIsOpen(false)}
//                       closeOnDocumentClick
//                       position="left top"
//                     >
//                       <div className="d-flex justify-content-between">
//                         <div>
//                           <h6
//                             className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
//                             // onClick={() => {
//                             //   window.location.reload(true)
//                             //   setShowMessageBox(true);
//                             // }}
//                             onClick={() => [navigate(
//                               `../${AdminRoute?.Common?.Aitherapist}`,
//                               { replace: true }
//                             ), window.location.reload(true)]}
//                           >
//                             New Session{" "}
//                           </h6>
//                           <h6
//                             className="mt-2 text-center font-weight-medium  dropdown-header cursor-pointer "
//                             // onClick={(e) => alert(e, JSON.parse(currentChatId))}
//                             onClick={() => [handleCurrentChat(currentChatId),
//                             navigate(
//                               `../${AdminRoute?.Common?.Aitherapist}?id=${currentChatId}`,
//                               { replace: true }
//                             )
//                             ]}
//                           >
//                             Current Session{" "}
//                           </h6>
//                         </div>

//                         {/* <div>
//                             {" "}
//                             <button className="logout-btn">
//                               <img
//                                 className="logout-img"
//                                 src={logoutImg}
                               
//                               />
//                             </button>
//                           </div> */}
//                       </div>
//                       <h6
//                         className="mt-2 font-weight-medium mb-2 dropdown-header cursor-pointer"
//                         onClick={() => {
//                           setShowPrevChat(!showPrevChat)
//                         }}
//                       >
//                         Previous Session{" "}
//                       </h6>
//                       {showPrevChat ? (
//                         !chatHistory?.length ? (
//                           <span className="d-block text-center p-2 border">
//                             Data not found
//                           </span>
//                         ) : (
//                           <>
//                             <div className="chat-histroy-box">
//                               {chatHistory?.length &&
//                                 chatHistory?.map((item, index) => (
//                                   <div key={index}>
//                                     <p
//                                       style={{ color: "white" }}
//                                       className="preview-subject ellipsis fw-light mb-0"
//                                     >
//                                       {moment(item?.date).format("DD MMM YYYY")}
//                                     </p>
//                                     {/* You may need a key for the outer map */}
//                                     {item?.messages
//                                       ?.slice(-20)
//                                       .map((subItem, subIndex) => (
//                                         <div
//                                           className="preview-item-content py-1" onClick={() => handleOnClick(false)}
//                                           key={subIndex}
//                                         >
//                                           <div className="font-weight-medium small-text mb-0 cursor-pointer d-flex justify-content-between px-4 ">
//                                             <div
//                                               onClick={() => [
//                                                 getMessages(subItem),
//                                                 navigate(
//                                                   `../${AdminRoute?.Common?.Aitherapist}?id=${subItem.message_id}`,
//                                                   { replace: true }
//                                                 )
//                                               ]}
//                                             >
//                                               {subItem?.first_message_by_user}
//                                             </div>
//                                             <span
//                                               className="fa fa-trash-o"
//                                               onClick={(e) =>
//                                                 getDeleteconversation(
//                                                   e,
//                                                   subItem
//                                                 )
//                                               }
//                                             ></span>
//                                             {/* <Popup
//                                                   className="confim-delete"
//                                                   trigger = {
                                                    
//                                                   }
//                                                 >
//                                                   <div className="">
//                                                     <div>
//                                                       <p>Do you want to delete this chat?</p>
//                                                     </div>
//                                                     <div className="d-flex">
//                                                       <span className="fa fa-check"></span>
//                                                       <span className="fa fa-times"></span>
//                                                     </div>
//                                                   </div>
//                                                 </Popup> */}
//                                           </div>
//                                         </div>
//                                       ))}
//                                   </div>
//                                 ))}
//                             </div>
//                           </>
//                         )
//                       ) : (
//                         <></>
//                       )}
//                       <h6 className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
//                         onClick={() => {
//                           setShowpayment(!showpayment);
//                         }}
//                       >
//                         {/* <NavLink
//                           to={"/Plan"}
//                           style={{
//                             color: "inherit",
//                             textDecoration: "inherit",
//                           }}
//                         > */}
//                         Payment{" "}
//                       </h6>
//                       {showpayment ? (<>
//                         <h6
//                           className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
//                         >
//                           <NavLink
//                             to={`../${RouteName?.Common?.PlanHistory}`}
//                             style={{
//                               color: "inherit",
//                               textDecoration: "inherit",
//                             }}
//                           >
//                             <span className="px-4">Billing History{" "}</span>
//                           </NavLink>

//                         </h6>
//                         <h6
//                           className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
//                         >
//                           <NavLink
//                             to={`../${RouteName?.Common?.Plan}/2`}
//                             style={{
//                               color: "inherit",
//                               textDecoration: "inherit",
//                             }}
//                           >
//                             <span className="px-4">Plans{" "}</span>
//                           </NavLink>
//                         </h6>
//                       </>) : (
//                         <></>
//                       )}
//                       {(getSUb_status?.subscription?.plan_id === 2 || getSUb_status?.subscription?.plan_id === 3)?<h6 className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
//                         onClick={() => {
//                           setShowtherapist(!showtherapist);
//                         }}
//                       >
//                         {/* <NavLink
//                           to={"/Plan"}
//                           style={{
//                             color: "inherit",
//                             textDecoration: "inherit",
//                           }}
//                         > */}
//                         Therapist{" "}
//                       </h6> : <></>}
//                       {showtherapist ? (<>
//                         <h6
//                           className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "  onClick={handleEveClick}
//                         >
//                           {/* <NavLink
//                             to={`../${RouteName?.Common?.PlanHistory}`}
//                             style={{
//                               color: "inherit",
//                               textDecoration: "inherit",
//                             }}
//                           > */}
//                             <span className="px-4" style={{
//                               color: "inherit",
//                               textDecoration: "inherit",
//                             }}>Eve{" "}</span>
//                           {/* </NavLink> */}

//                         </h6>
//                         <h6
//                           className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer " onClick={handleAdamClick}
//                         >
//                           {/* <NavLink
//                             to={`../${RouteName?.Common?.PlanHistory}`}
//                             style={{
//                               color: "inherit",
//                               textDecoration: "inherit",
//                             }}
//                           > */}
//                             <span className="px-4" style={{
//                               color: "inherit",
//                               textDecoration: "inherit",
//                             }}>Adam{" "}</span>
//                           {/* </NavLink> */}

//                         </h6>
//                       </>) : (
//                         <></>
//                       )}
                       
//                       <h6
//                         className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
//                         onClick={(e) => handleLogout(e)}
//                       >
//                         Logout{" "}
//                       </h6>
//                     </Popup>
//                   </div>
//                 </div>

//                 <div className="col-md-12 mb-2">
//                   {/* Added padding 0  */}


//                   <div
//                     className="therapist-content-center position-relative therapist-inner-wrapper p-0"

//                   >
//                     {/* Added height */}

//                     <div className="therapist-inner-wrapper"

//                     >
//                       <div className="inner-content ">
//                         <ReactScrollableFeed>
//                           {/* <h2>Welcome to Al Therapist</h2>
//                     <p>
//                       Lorem ipsum is placeholder text commonly used in the
//                       graphic, print, and publishing industries for previewing
//                       layouts and visual mockups. Lorem Ipsum Generator.
//                     </p> */}

//                           {/* =============================================================== */}

//                           {AIConversation?.length
//                             ? AIConversation?.map((val, index) => {
//                               return (
//                                 <div className="mb-3" key={index}>
//                                   {val?.role === "assistant" ? (
//                                     <div className="chatbox-rec">
//                                       <div className="chatbox-rec chat-reciving-light">
//                                         <p className="my-0 reciving-text">
//                                           {val?.content}
//                                         </p>
//                                       </div>
//                                     </div>
//                                   ) : (
//                                     <div className="chatbox-send">
//                                       <div className="chat-trasmitting-light">
//                                         <p className="my-0 trasmitting-text">
//                                           {val?.content}
//                                         </p>
//                                       </div>
//                                     </div>
//                                   )}
//                                 </div>
//                               );
//                             })
//                             : AIChat?.output?.content && (
//                               <div className="chatbox-rec">
//                                 <div className="chatbox-rec chat-reciving-light">
//                                   <p className="my-0 reciving-text">
//                                     {AIChat?.output?.content}
//                                   </p>
//                                 </div>
//                               </div>
//                             )}
//                           {isGenerating ? (
//                             <div className="loading">
//                               <span className="loading__dot"></span>
//                               <span className="loading__dot"></span>
//                               <span className="loading__dot"></span>
//                             </div>
//                           ) : (
//                             <></>
//                           )}

//                           {/* <div className="dot-pulse"></div> */}
//                           {/* <p className="dot-pulse"> Typing... </p>  */}

//                           {/* ================================= */}
//                           {/* <p>{AIChat?.output}</p>
                    
//                     <h2>{AiInputChatDisplay}</h2>
//                     <p>{AiOutput?.message}</p>

//                     {AIConversation?.length &&
//                       AIConversation?.map((val, index) => {
//                         return (
//                           <>
//                             <p key={index}>{val?.content}</p>
//                           </>
//                         );
//                       })} */}

//                         </ReactScrollableFeed>
//                       </div>

//                     </div>


//                     {showMessageBox ? (<div
//                       className="send-box"
//                       style={{ display: isRecording ? "none" : null }}
//                     >
//                       <textarea
//                         // type="text"
//                         ref={textareaRef}
//                         className="send-box-input"
//                         placeholder="Write a message..."
//                         // tabIndex="0"
//                         rows={rows}
//                         cols={50}
//                         // autoComplete="off"
//                         // disabled={isGenerating}
//                         value={isGenerating ? "" : AiInputChat}
//                         // defaultValue={transcript}
//                         // onChange={(e) => setAiInputChat(e.target.value)}
//                         onChange={(e) => {
//                           handleChange(e);
//                         }}
//                         onKeyDown={handleKeyPress}
//                         style={{ resize: "none" }}
//                       // value={transcript}
//                       ></textarea>

//                       <div className="mb-4">

//                         <Button
//                           className="send_btn"
//                           title="Send message"
//                           tabIndex="0"
//                           onClick={(e) => handleSubmitChat(e)}
//                         >
//                           <svg
//                             className="btn__icon"
//                             viewBox="0 0 24 24"
//                             fill="none"
//                             stroke="currentColor"
//                             strokeWidth="2"
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                           >
//                             <line x1="22" y1="2" x2="11" y2="13"></line>
//                             <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
//                           </svg>
//                         </Button>
//                         {/* <div className="send-box-icon">
                          
//                         </div> */}
//                       </div>
//                       {/* audio input code start */}
//                       {/* <div className="send-box-icon">
//                       <Button
//                         className="btn p-0 m-0"
//                         title=""
//                         tabIndex="0"
//                         // onClick={()=> alert("Audio button")}
//                         onClick={() => startListening()}
//                       >
//                         <img
//                           src="https://www.svgrepo.com/show/1902/microphone.svg"
//                           className="mic"
//                           alt="icon"
//                         />
//                       </Button>
//                     </div> */}
//                       {(getSUb_status?.plan[0]?.plan_name === "Premium" || getSUb_status?.plan[0]?.plan_name === "Trial") ?
//                         <>
//                           <AudioRecorder
//                             onRecordingComplete={(blob) => addAudioElement(blob)}
//                             onClick={() => onRecord(isRecording)}
//                             recorderControls={recorderControls}
//                             downloadOnSavePress={false}
//                             downloadFileExtension="mp3"
//                             showVisualizer={true}
//                           />
//                         </>
//                         :
//                         <></>
//                       }
//                       {/* <button onClick={recorderControls.stopRecording}>Stop</button> */}
//                       <div><audio ref={audioRef} style={{ display: 'none' }} controls>
//                         <source src={`data:audio/mp3;base64,${response?.data?.buffer}`} type="audio/mp3" />
//                         Your browser does not support the audio tag.
//                       </audio>
//                       </div>
//                       <br />
//                       {/* audio input code ends */}
//                     </div>) : (
//                       <></>
//                     )}
//                   </div>

//                 </div>

//               </div>
//             </div>
//             {/* </div> */}
//             {/* <Footer /> */}
//           </div>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default Aitherapist;

//==========================================================================//

// eslint-disable
import React, { useEffect, useState, useRef, useReducer } from "react";
import Button from "react-bootstrap/Button";
import ReactScrollableFeed from 'react-scrollable-feed';
import axios from "axios";
import { toast } from "react-toastify";
import {
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import AdminRoute from "../../Route/RouteDetails";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import commoan from "../../environment/Config";
import moment from "moment";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import power from "../../Assets/images/power.png";
import adam from "../../Assets/images/adam.png";
import checkmark from "../../Assets/images/checkmark.png";
import RouteName from "../../Route/RouteDetails";

const Aitherapist = ({ messages }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const megID = queryParams.get("id");
  const [currentChatId, setcurrentChatId] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [getSUb_status, setgetSUb_status] = useState();
  const navigate = useNavigate();
  const authToken = JSON.parse(localStorage.getItem("AuthTokenAI"));
  const mobile = localStorage.getItem("InputType");
  const [AIChat, setAIChat] = useState([]);
  const [AiInputChat, setAiInputChat] = useState("");
  const [AiOutput, setAiOutput] = useState([]);
  // const [AllAiChat, setAllAiChat] = useState([]);
  const [AIConversation, setAIConversation] = useState([]);
  const [isGenerating, setisGenerating] = useState(false);
  const [useCase, setUseCase] = useState(4);
  const [chatHistory, setChatHistory] = useState([]);
  const [Available_Time_Minutes, setAvailable_Time_Minutes] = useState();
  const [Available_Time_Seconds, setAvailable_Time_Seconds] = useState();
  const [rows, setRows] = useState(1);
  const [time, setTime] = useState(0);
  const [showPrevChat, setShowPrevChat] = useState(false);
  const [showpayment, setShowpayment] = useState(false);
  const [showtherapist, setShowtherapist] = useState(false);
  const [showAudio, setShowaudio] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(true);
  const [response, setResponse] = useState(null);
  const [viewState, setViewState] = useState(true);
  const [initialAiVoiceCount, setInitialAiVoiceCount] = useState(1);
  const [audioOutOnOff, setAudioOutOnOff] = useState(true);
  const audioRef = useRef(null);

  const textareaRef = useRef(null);

  const scrollableDivRef = useRef(null);

  const onRecord = (recState) => {
    setIsRecording(!recState);
  };

  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
    }
  };

  const messageEndRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const handleOnClick = (st) => {
    setIsOpen(st);
  }

  const scrollToLastMessage = () => {
  }

  useEffect(() => {

    scrollToLastMessage();
  }, [AIConversation])

  function addAIMessage(msg) {

    let timer = setTimeout(() => setisGenerating(true), 1e1);

    AIConversation?.length == 0
      ? setAIConversation((prevArray) => [
        ...prevArray,
        {
          content: AIChat?.output?.content,
          created_at: "2023-10-23T08:02:07.770Z",
          role: "assistant",
        },
        {
          content: msg,
          created_at: "2023-10-23T08:02:07.770Z",
          role: "user",
        },
      ])
      : setAIConversation((prevArray) => [
        ...prevArray,
        {
          content: msg,
          created_at: "2023-10-23T08:02:07.770Z",
          role: "user",
        },
      ]);

    try {
      let data = JSON.stringify({
        use_case_id: useCase,
        content: msg,
        message_id: megID ? megID : AIChat?.message_id,
        phone: mobile,
        audio_out: audioOutOnOff,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}input_chat`,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
        data: data,
      };
      
      axios.request(config).then((response) => {
        if (response?.data?.message === 'Subscription not found' || response?.data?.message === 'Subscription not active' || response?.data?.message === 'Subscription expired' || response?.data?.message === 'Seconds exhausted') {
navigate(`../${AdminRoute?.Common?.Plan}/1`);}
        if (response?.data?.result === "success") {
          setAiOutput(response?.data?.data);
          setResponse(response?.data?.data);
          setAiInputChat("");
          setisGenerating(false);
          clearTimeout(timer);
          setAvailable_Time_Minutes(
            Math.floor(response?.data?.available_time / 60)
          );
          setAvailable_Time_Seconds(response?.data?.available_time % 60);
          let plan_time = getSUb_status?.plan[0]?.seconds
          let subscription_time = response?.data?.data?.available_time
          setTime(Math.abs(plan_time - subscription_time));
          navigate(
            `../${AdminRoute?.Common?.Aitherapist}?id=${response?.data?.data?.message_id}`,
            { replace: true }
          );
          try {
            const qs = require("qs");
            let data = qs.stringify({
              message_id: megID ? megID : AIChat?.message_id,
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: `${commoan.baseUrl}getConversation`,
              headers: {
                authorization: authToken,
              },
              data: data,
            };

            axios.request(config).then((response) => {
              setAIConversation(response?.data?.data?.message);
            });
          } catch (error) {
          }
        }
      });
    } catch (error) {
    }
    scrollToBottom();
  }

  const sendBinaryDataToBackend = async (blob) => {
    
    setViewState(prev => !prev); 
    const data = new FormData();

    data.append("file", blob, "output.mp3");

    axios
      .post(`${commoan.baseUrl}transcribe`, data, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((response) => {
        addAIMessage(response.data?.transcription);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.data?.message);
      });
  };
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const addAudioElement = (blob) => {
   
    if(recorderControls.recordingBlob !== undefined){
  
    sendBinaryDataToBackend(blob);
  }else{
   
    // recorderControls.recordingBlob = blob;
   
    return;
  }
    // const url = URL.createObjectURL(blob);
    // const audio = document.createElement("audio");
    // audio.src = url;
    // audio.controls = true;
    // document.body.appendChild(audio);
  };


  const theme = {
    "--bg-color": useCase == "4" ? "#db95db" : "#3857b3",
  };
  // =====================api call for grt user info =================================

  useEffect(() => {
    if (megID) {
      getMessages(megID, 'old');
    }

    HistoryConversation();
  }, []);

  // =====================api call for first input chat  =================================

  const HistoryConversation = () => {
    // console.log("HistoryConversation  : being called")
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}getConversationHistory`,
        headers: {
          Authorization: authToken,
        },
      };

      axios.request(config).then((response) => {
        setChatHistory(response?.data?.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    debugger
    if (!megID) {
      // console.log("useEffect 1  : being called")
      const initailChat = () => {
        try {
          let data = {
            use_case_id: useCase,
            content: "",
            phone: mobile,
            audio_out: audioOutOnOff,
          };

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${commoan.baseUrl}input_chat`,
            headers: {
              Authorization: authToken,
            },
            data: data,
          };
         
          axios.request(config).then((response) => {
            if (response?.data?.message === 'Subscription not found' || response?.data?.message === 'Subscription not active' || response?.data?.message === 'Subscription expired' || response?.data?.message === 'Seconds exhausted') {

              navigate(`../${AdminRoute?.Common?.Plan}/1`);
            }
            // console.log("response data of 2 input_chat ", response);
            setInitialAiVoiceCount(0);
            setResponse(response?.data);
            setAIChat(response?.data);
            setcurrentChatId(response?.data?.message_id);

            setAvailable_Time_Minutes(
              Math.floor(response?.data?.available_time / 60)
            );
            setAvailable_Time_Seconds(response?.data?.available_time % 60);
            navigate(
              `../${AdminRoute?.Common?.Aitherapist}?id=${response?.data?.message_id}`,
              { replace: true }
            );

            if (response?.data?.error_code === "TRIAL_EXPIRED") {
              toast.error(response?.data?.message);

            }
          });
        } catch (error) {
          toast.error(error?.data?.message);
          if (error?.data?.message === 'Subscription not found' || error?.data?.message === 'Subscription not active' || error?.data?.message === 'Subscription expired' || error?.data?.message === 'Seconds exhausted') {
            navigate(`../${AdminRoute?.Common?.Plan}`);
          }
        }
      }

      initailChat();
    }
  }, []);



  const handleChange = (e) => {
    setAiInputChat(e.target.value);
  };

  useEffect(() => {
    const textarea = textareaRef.current;
    if (rows < 5) {
      setRows(Math.max(1, Math.floor(textarea.scrollHeight / 30)));
    }
    if (AiInputChat.length === 0) {
      setRows(1);
    }
  }, [AiInputChat]);

  // =====================api call for chat section =================================

  const handleSubmitChat = (e) => {
    setRows(1);
    e.preventDefault();
    // console.log("handleSubmitChat  : being called")
    if (AiInputChat != "") {
      AIConversation?.length == 0
        ? setAIConversation((prevArray) => [
          ...prevArray,
          {
            content: AIChat?.output?.content,
            created_at: "2023-10-23T08:02:07.770Z",
            role: "assistant",
          },
          {
            content: AiInputChat,
            created_at: "2023-10-23T08:02:07.770Z",
            role: "user",
          },
        ])
        : setAIConversation((prevArray) => [
          ...prevArray,
          {
            content: AiInputChat,
            created_at: "2023-10-23T08:02:07.770Z",
            role: "user",
          },
        ]);

      let timer = setTimeout(() => setisGenerating(true), 1e1);
      try {
        let data = JSON.stringify({
          use_case_id: useCase,
          content: AiInputChat,
          message_id: megID ? megID : AIChat?.message_id,
          phone: mobile,
          audio_out: audioOutOnOff,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${commoan.baseUrl}input_chat`,
          headers: {
            Authorization: authToken,
            "Content-Type": "application/json",
          },
          data: data,
        };
     
        axios.request(config).then((response) => {
          if (response?.data?.message === 'Subscription not found' || response?.data?.message === 'Subscription not active' || response?.data?.message === 'Subscription expired' || response?.data?.message === 'Seconds exhausted') {
            navigate(`../${AdminRoute?.Common?.Plan}/1`);
          }
          if (response?.data?.result === "success") {
            setResponse(response?.data?.data);
            setAiInputChat("");
            // console.log(response?.data?.data.message_id);
            navigate(
              `../${AdminRoute?.Common?.Aitherapist}?id=${response?.data?.data.message_id}`,
              { replace: true }
            );
            // }
            setAIConversation((prevArray) => [
              ...prevArray,
              {
                content: response?.data?.data?.message,
                created_at: "2023-10-23T08:02:07.770Z",
                role: "assistant",
              },
            ]);
            setisGenerating(false);
            clearTimeout(timer);
            setAvailable_Time_Minutes(
              Math.floor(response?.data?.data?.available_time / 60)
            );
            setAvailable_Time_Seconds(response?.data?.data?.available_time % 60);
            let plan_time = getSUb_status?.plan[0]?.seconds
            let subscription_time = response?.data?.data?.available_time
            setTime(Math.abs(plan_time - subscription_time));
            
          } else {
            toast.error(response?.data?.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else toast.error("Input text must not be empty");
    scrollToBottom();
  };

  //   ========Get conversation api =========
  const getMessages = (conversation, check) => {
    try {
      const qs = require("qs");
      let data = qs.stringify({
        message_id: check ? conversation : conversation?.message_id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}getConversation`,
        headers: {
          authorization: authToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios.request(config).then((response) => {
        setAIConversation(response?.data?.data?.message);
      });
    } catch (error) {
      console.log(error);
    }
  };
  // =================== input chat section api call end=================

  //side Effect

  useEffect(() => {
    scrollToBottom();
  }, [isGenerating]);


  const handleLogout = (e) => {
    e.preventDefault();
    let data = "";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${commoan.baseUrl}logout`,
      headers: {
        Authorization: authToken,
      },
      data: data,
    };

    axios.request(config).then((response) => {
      if (response?.data?.result === "success") {
        navigate(`../${AdminRoute?.Auth?.Login}`);
      }
    })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate(`../${AdminRoute?.Auth?.Login}`);

        }
      })
  }

  //enter input text function
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      setRows(1);
      handleSubmitChat(e);
      setAiInputChat("");
      e.preventDefault();
    } else if (e.key === "Enter" && e.shiftKey) {
      if (rows < 6) {
        setRows(rows + 1);
      }
      const cursorPosition = e.target.selectionStart;
      const newInputValue =
        AiInputChat.substring(0, cursorPosition) +
        "\n" +
        AiInputChat.substring(cursorPosition);
      setAiInputChat(newInputValue);
      e.preventDefault();
    }
  };

  // delete conversatation api
  const getDeleteconversation = (e, id) => {
    try {
      const qs = require("qs");
      let data = qs.stringify({
        message_id: id?.message_id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}deleteConversation`,
        headers: {
          authorization: authToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        HistoryConversation();
      });
    } catch (error) {
      console.log(error);
    }
  };

  // ===========Current Chat ========

  const handleCurrentChat = (id) => {
    try {
      const qs = require("qs");
      let data = qs.stringify({
        message_id: id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}getConversation`,
        headers: {
          authorization: authToken,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        setAIConversation(response?.data?.data?.message);
      });
    } catch (error) {
      console.log(error);
    }
  };



  //Time and seconnds

  useEffect(() => {
    debugger
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${commoan?.baseUrl}subscription/getSubscriptionStatus`,
      headers: {
        'authorization': authToken
      }
    };
    axios.request(config)
      .then((response) => {
        if (response.data.success) {
          setTime(Math.abs(response.data.plan[0].seconds - response.data.subscription.seconds));
          setgetSUb_status(response.data);
          setUseCase(response.data.subscription.use_case_id)
        }else{
          debugger
          toast.error(response?.data?.message);
          // navigate(`../${AdminRoute?.Common?.Plan}/1`);

        }
      })
      .catch((error) => {
        console.log(error);
        navigate(`../${AdminRoute?.Common?.Plan}/1`);

      });
  }, []);



  useEffect(() => {
    let plan_time = getSUb_status?.plan[0]?.seconds
    let subscription_time = getSUb_status?.subscription?.seconds
    setTime(Math.abs(plan_time - subscription_time));
  }, [])



  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.src = '';
    }
  };

  useEffect(() => {
    const handleAudioEnded = () => {
      // Reset audio when playback ends
      resetAudio();
    };

    const audioElement = audioRef.current;

    if (audioElement) {
      audioElement.addEventListener('ended', handleAudioEnded);

      // Cleanup function to remove event listener
      return () => {
        audioElement.removeEventListener('ended', handleAudioEnded);
      };
    }
  }, [audioRef]);

  useEffect(() => {
    if (response && response.buffer && response.buffer !== "") {
      // Play audio when buffer data is received
      if (audioRef.current) {
        // if(recorderControls.isRecording === false){
        audioRef.current.src = `data:audio/mp3;base64,${response.buffer}`;
        audioRef.current.play();
      // }
      }
    }else if (response && response.buffer === "") {
      setViewState(prev => !prev);
    }
  }, [response]);


  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => { // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (status === 401) {
      window.location = window.location.protocol + "//" + window.location.host + "/#/Login"
    } else {
      return Promise.reject(error); // Delegate error to calling side
    }
  });
  useEffect(() => {
    // console.log("useEffect time :", time);

  }, [time]);

  const handleAdamClick = () => {

    // Make API call
    try {
     
        debugger
        // console.log("getSUb_status :",getSUb_status)
      let data = JSON.stringify({
        // user_id: getSUb_status?.subscription?.user_id,
        use_case_id:  4 ,
        
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}changeUsecase`,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        
        if(response?.data){
        } else {
          toast.error(response?.data?.message);
        }
      });
      setUseCase(4);
   }catch (error) {
      console.log(error);
    }
  };

  const handleEveClick = () => {

    // Make API call
    try {
      let data = JSON.stringify({
        use_case_id:  2,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${commoan.baseUrl}changeUsecase`,
        headers: {
          Authorization: authToken,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        
        if(response?.data){
         } else {
          toast.error(response?.data?.message);
        }
      });
      setUseCase(2);
   }catch (error) {
      console.log(error);
    }
  };

  const handleshowAudioOnClick = () => {
    setAudioOutOnOff(true) ;
   
  };

  const handleshowAudioOffClick = () => {
    setAudioOutOnOff(false) ;
  };

  useEffect(() => {
    
  }, [audioOutOnOff]);

  // useEffect(() => {
  //   if(recorderControls.isRecording){
  //     console.log("useEffect isRecording")
  //     if (audioRef.current) {
  //       console.log("useEffect isRecording audioRef.current")
  //       audioRef.current.src = '';
  //   }
  //   }
  // },[recorderControls.isRecording])
  useEffect(() => {
    
    // console.log("useEffect recorderControls.recordingBlob : ",recorderControls.recordingBlob)
    addAudioElement(recorderControls.recordingBlob)
    // console.log(" recordingBlob,isRecording,isPaused,recordingTime,mediaRecorder :",recorderControls.recordingBlob,recorderControls.isRecording,recorderControls.isPaused,recorderControls.recordingTime,recorderControls.mediaRecorder)
  },[recorderControls.recordingBlob])

  const handleAudioEnd = () => {
    // Trigger your event or perform any action here
    if(initialAiVoiceCount){
    // console.log("Audio playback ended");
    recorderControls.recordingBlob = undefined;
    // console.log("handleAudioEnd recorderControls.recordingBlob : ",recorderControls.recordingBlob)
    setViewState(prev => !prev);
    resetAudio();
    }
    setInitialAiVoiceCount(prev => prev + 1);
  };
  //jsx
  return (
    <div className="d-flex h-dashboard">
      <div className="toggleIcon aitherapist">
        <div className="container-fluid">
          <div className="row">
            <div
              className={
                "ai-div " + (useCase === 2 ? " dark-theme" : "dark-theme")
              }
            >
              <div className="ai-div__inner">
                <div className="switches-div d-flex">
                  <div>
                    <div className="icon-div">
                      <img src={useCase === 2 ? power : useCase === 4 ? adam : ""} className="User" alt="icon" />
                    </div>
                    {useCase === 2 ?<span className="toggle-label">Eve</span> : useCase === 4 ? <span className="toggle-label">Adam</span> : ""}
                  </div>

                  <div className="Avail-Time">
                    <div className="Avail-Toggle">
                      <label className="switch">
                        <input type="checkbox" id="togBtn" />
                      </label>
                    </div>
                  </div>

                  <span className="Avail-Time-Text">

                    {time === 0 || isNaN(time) ? <></> : (
                      <span>
                        {Math.floor(time / 3600) !== 0 ? (
                          <span>{Math.floor(time / 3600)} hr  {Math.floor(time % 3600 / 60)} min</span>
                        ) : (
                          <span>{Math.floor(time % 3600 / 60)} min</span>
                        )}
                      </span>
                    )}
                  </span>
                  <div className="d-flex">
                    <ul className="dropbtn icons mb-0" onClick={() => handleOnClick(true)}>
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                    <Popup
                      className="chat-histroy-pop-up "
                      open={isOpen}
                      onClose={() => setIsOpen(false)}
                      closeOnDocumentClick
                      position="left top"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <h6
                            className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                            onClick={() => [navigate(
                              `../${AdminRoute?.Common?.Aitherapist}`,
                              { replace: true }
                            ), window.location.reload(true)]}
                          >
                            New Session{" "}
                          </h6>
                          <h6
                            className="mt-2 text-center font-weight-medium  dropdown-header cursor-pointer "
                            onClick={() => [handleCurrentChat(currentChatId),
                            navigate(
                              `../${AdminRoute?.Common?.Aitherapist}?id=${currentChatId}`,
                              { replace: true }
                            )
                            ]}
                          >
                            Current Session{" "}
                          </h6>
                        </div>
                      </div>
                      <h6
                        className="mt-2 font-weight-medium mb-2 dropdown-header cursor-pointer"
                        onClick={() => {
                          setShowPrevChat(!showPrevChat)
                        }}
                      >
                        Previous Session{" "}
                      </h6>
                      {showPrevChat ? (
                        !chatHistory?.length ? (
                          <span className="d-block text-center p-2 border">
                            Data not found
                          </span>
                        ) : (
                          <>
                            <div className="chat-histroy-box">
                              {chatHistory?.length &&
                                chatHistory?.map((item, index) => (
                                  <div key={index}>
                                    <p
                                      style={{ color: "white" }}
                                      className="preview-subject ellipsis fw-light mb-0"
                                    >
                                      {moment(item?.date).format("DD MMM YYYY")}
                                    </p>
                                    {/* You may need a key for the outer map */}
                                    {item?.messages
                                      ?.slice(-20)
                                      .map((subItem, subIndex) => (
                                        <div
                                          className="preview-item-content py-1" onClick={() => handleOnClick(false)}
                                          key={subIndex}
                                        >
                                          <div className="font-weight-medium small-text mb-0 cursor-pointer d-flex justify-content-between px-4 ">
                                            <div
                                              onClick={() => [
                                                getMessages(subItem),
                                                navigate(
                                                  `../${AdminRoute?.Common?.Aitherapist}?id=${subItem.message_id}`,
                                                  { replace: true }
                                                )
                                              ]}
                                            >
                                              {subItem?.first_message_by_user}
                                            </div>
                                            <span
                                              className="fa fa-trash-o"
                                              onClick={(e) =>
                                                getDeleteconversation(
                                                  e,
                                                  subItem
                                                )
                                              }
                                            ></span>
                                            
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                ))}
                            </div>
                          </>
                        )
                      ) : (
                        <></>
                      )}
                      <h6 className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                        onClick={() => {
                          setShowpayment(!showpayment);
                        }}
                      >
                        Payment{" "}
                      </h6>
                      {showpayment ? (<>
                        <h6
                          className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                        >
                          <NavLink
                            to={`../${RouteName?.Common?.PlanHistory}`}
                            style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}
                          >
                            <span className="px-4">Billing History{" "}</span>
                          </NavLink>

                        </h6>
                        <h6
                          className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                        >
                          <NavLink
                            to={`../${RouteName?.Common?.Plan}/2`}
                            style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}
                          >
                            <span className="px-4">Plans{" "}</span>
                          </NavLink>
                        </h6>
                      </>) : (
                        <></>
                      )}
                      {(getSUb_status?.subscription?.plan_id === 2 || getSUb_status?.subscription?.plan_id === 3 || getSUb_status?.subscription?.plan_id === 4)?<h6 className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                        onClick={() => {
                          setShowtherapist(!showtherapist);
                        }}
                      >
                        Therapist{" "}
                      </h6> : <></>}
                      {/* {showtherapist ? (<>
                        <h6
                          className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "  onClick={handleEveClick}
                        >
                            <span className="px-4" style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}>Eve{" "}</span>
                          {useCase === 2 ? <img src={ checkmark} className="User" alt="" /> : <></>}
                        </h6>
                        <h6
                          className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer " onClick={handleAdamClick}
                        >
                            <span className="px-4" style={{
                              color: "inherit",
                              textDecoration: "inherit",
                            }}>Adam{" "}</span>
                            {useCase === 4 ? <img src={ checkmark} className="User" alt="" /> : <></>}
                          </h6>
                      </>) : (
                        <></>
                      )} */}
                      {showtherapist ? (
  <>
    <h6
      className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
      onClick={handleEveClick}
    >
      <input
        type="radio"
        checked={useCase === 2}
        // onChange={handleEveChange}
        className="radio-button"
      />
      <span className="px-4" style={{
        color: "inherit",
        textDecoration: "inherit",
      }}>Eve{" "}</span>
    </h6>
    <h6
      className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
      onClick={handleAdamClick}
    >
      <input
        type="radio"
        checked={useCase === 4}
        // onChange={handleAdamChange}
        className="radio-button"
      />
      <span className="px-4" style={{
        color: "inherit",
        textDecoration: "inherit",
      }}>Adam{" "}</span>
    </h6>
  </>
) : (
  <></>
)}
                         <h6 className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                        onClick={() => {
                          setShowaudio(!showAudio);
                        }}
                      >
                        Audio{" "}
                      </h6> 
                      {showAudio ? (
  <>
    <h6
      className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
      onClick={handleshowAudioOnClick}
    >
      <input
        type="radio"
        checked={audioOutOnOff === true}
        // onChange={handleEveChange}
        className="radio-button"
      />
      <span className="px-4" style={{
        color: "inherit",
        textDecoration: "inherit",
      }}>On{" "}</span>
    </h6>
    <h6
      className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
      onClick={handleshowAudioOffClick}
    >
      <input
        type="radio"
        checked={audioOutOnOff === false}
        // onChange={handleAdamChange}
        className="radio-button"
      />
      <span className="px-4" style={{
        color: "inherit",
        textDecoration: "inherit",
      }}>Off{" "}</span>
    </h6>
  </>
) : (
  <></>
)}
                      <h6
                        className="mt-2 text-start font-weight-medium  dropdown-header cursor-pointer "
                        onClick={(e) => handleLogout(e)}
                      >
                        Logout{" "}
                      </h6>
                    </Popup>
                  </div>
                </div>

                <div className="col-md-12 mb-2">
                  {/* Added padding 0  */}


                  <div
                    className="therapist-content-center position-relative therapist-inner-wrapper p-0"

                  >
                    {/* Added height */}

                    <div className="therapist-inner-wrapper"

                    >
                      <div className="inner-content ">
                        <ReactScrollableFeed>

                          {/* =============================================================== */}

                          {AIConversation?.length
                            ? AIConversation?.map((val, index) => {
                              return (
                                <div className="mb-3" key={index}>
                                  {val?.role === "assistant" ? (
                                    <div className="chatbox-rec">
                                      <div className="chatbox-rec chat-reciving-light">
                                        <p className="my-0 reciving-text">
                                          {val?.content}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="chatbox-send">
                                      <div className="chat-trasmitting-light">
                                        <p className="my-0 trasmitting-text">
                                          {val?.content}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })
                            : AIChat?.output?.content && (
                              <div className="chatbox-rec">
                                <div className="chatbox-rec chat-reciving-light">
                                  <p className="my-0 reciving-text">
                                    {AIChat?.output?.content}
                                  </p>
                                </div>
                              </div>
                            )}
                          {isGenerating ? (
                            <div className="loading">
                              <span className="loading__dot"></span>
                              <span className="loading__dot"></span>
                              <span className="loading__dot"></span>
                            </div>
                          ) : (
                            <></>
                          )}

                        </ReactScrollableFeed>
                      </div>

                    </div>


                    {showMessageBox ? (<div
                      className="send-box"
                      style={{ display: isRecording ? "none" : null }}
                    >
                      <textarea
                        // type="text"
                        ref={textareaRef}
                        className="send-box-input"
                        placeholder="Write a message..."
                        // tabIndex="0"
                        rows={rows}
                        cols={50}
                        value={isGenerating ? "" : AiInputChat}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onKeyDown={handleKeyPress}
                        style={{ resize: "none" }}
                      ></textarea>

                      <div className="mb-4">

                        <Button
                          className="send_btn"
                          title="Send message"
                          tabIndex="0"
                          onClick={(e) => handleSubmitChat(e)}
                        >
                          <svg
                            className="btn__icon"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="22" y1="2" x2="11" y2="13"></line>
                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                          </svg>
                        </Button>
                      </div>
                      {(getSUb_status?.plan[0]?.plan_name === "Premium" || getSUb_status?.plan[0]?.plan_name === "Trial" || getSUb_status?.plan[0]?.plan_name === "Premium Monthly" ) ?
                        viewState === true  ? (<>
                          <AudioRecorder
                            // onRecordingComplete={(blob) => addAudioElement(blob)}
                            onClick={() => onRecord(isRecording)}
                            recorderControls={recorderControls}
                            downloadOnSavePress={false}
                            downloadFileExtension="mp3"
                            showVisualizer={true}
                          />
                        </>  ) : <></>
                        :
                        <></>
                      }
                      <div><audio ref={audioRef} style={{ display: 'none' }} controls onEnded={handleAudioEnd}>
                        <source src={`data:audio/mp3;base64,${response?.data?.buffer}`} type="audio/mp3" />
                        Your browser does not support the audio tag.
                      </audio>
                      </div>
                      <br />
                    </div>) : (
                      <></>
                    )}
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Aitherapist;
