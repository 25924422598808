import React from "react";

const CommonTable = () => {
    const data = [
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
         { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
        { orderId: "XXXX", feature: 'Book A session', price: "1000/-", paymantDate: "26/11/2023", expiryDate: "Male" },
    ]
    return(
        <div className="table-container">
            <table className="common-table" cellspacing="3">
                <tr className="table-head">
                    <th>Order Id</th>
                    <th>Feature</th>
                    <th>Price</th>
                    <th>Payment Date</th>
                    <th>Expiry Date</th>
                </tr>
                {data.map((val, key) => {
                    return (
                        <tr key={key} className="table-deff">
                            <td>{val.orderId}</td>
                            <td>{val.feature}</td>
                            <td>{val.price}</td>
                            <td>{val.paymantDate}</td>
                            <td>{val.expiryDate}</td>
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}

export default CommonTable;