
const RouteName = {
  
    Auth: {
        Login: '/Login',
        VerifyOtp: '/VerifyOtp',
        Register:'/Register'

    },
    Common:{
        Aitherapist:'/',
        Plan:'/Plan',
        Success: '/Success',
        Failed:'/Failed',
        CommonTable: '/CommonTable',
        PlanHistory:'/PlanHistory',
        Sample:'/sample',
        // PlanPage:'/PlanPage',
        PageNotFound: '*',
    },
   

}

export default RouteName;