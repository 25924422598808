import React, {useState, useEffect} from "react";

import power from "../../Assets/images/power.png";
import send from '../../Assets/images/send.png';

import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";

const Sample = () => {

    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

 useEffect(() => {
    if ('visualViewport' in window) {
        const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
        window.visualViewport.addEventListener('resize', function (event) {
          if (
            (event.target.height * event.target.scale) / window.screen.height <
            VIEWPORT_VS_CLIENT_HEIGHT_RATIO
          ) {
            setKeyboardVisible(true);
          }
          else {
            setKeyboardVisible(false);
          } 
        });
      }
  }, []);
    
  const [isRecording, setIsRecording] = useState(false);
    const onRecord = (recState) => {
        setIsRecording(!recState);
      };

    const recorderControls = useAudioRecorder(
        {
          noiseSuppression: true,
          echoCancellation: true,
        },
        (err) => console.table(err) // onNotAllowedOrFound
      );
      const addAudioElement = (blob) => {
        // sendBinaryDataToBackend(blob);
        // const url = URL.createObjectURL(blob);
        // const audio = document.createElement("audio");
        // audio.src = url;
        // audio.controls = true;
        // document.body.appendChild(audio);
      };
    return (
        <div className="app-cont" style={{
            // height: isKeyboardVisible ?  'calc(100vh - 300px)' : 'calc(100vh - 0px)'
        }}>
            <div className="app-head-cont">
                <img style={{width: '50px', height: '50px'}} src={power} />
                <label>Avail Time: 12:00</label>
                <ul className="dropbtn icons mb-0">
                          <li></li>
                          <li></li>
                          <li></li>
                        </ul>
            </div>
            <div className="app-chat-cont" style={{height: isKeyboardVisible ? "calc(100% - 300px)" : "calc(100% - 140px)",}}>
                <div className="scroller" style={{
                      maxHeight: "calc(100% - 15px)",
                    padding: '0px 10px'
                }}>
                    <div className="scroller-content">
                        <div
                            className="item my-3 recive-msg"
                            style={{ margin: "10px 0px" }}
                        >
                            <div className="msg-card recived-msg-card">
                                <div className="mx-2 my-2 msg-lbl">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing
                                    elit. Omnis dolores explicabo iure repudiandae, quo
                                    repellat. Reiciendis facere maxime in commodi
                                    voluptatibus quos hic optio, culpa magni, a, dolorem
                                    recusandae cumque.
                                </div>
                            </div>
                        </div>

                        <div
                            className="item recive-msg"
                            style={{ margin: "10px 0px" }}
                        >
                            <div className="msg-card recived-msg-card">
                                <div className="mx-2 my-2 msg-lbl">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing
                                    elit. Omnis dolores explicabo iure repudiandae, quo
                                    repellat. Reiciendis facere maxime in commodi
                                    voluptatibus quos hic optio, culpa magni, a, dolorem
                                    recusandae cumque.
                                </div>
                            </div>
                        </div>
                        <div
                            className="item recive-msg"
                            style={{ margin: "10px 0px" }}
                        >
                            <div className="msg-card recived-msg-card">
                                <div className="mx-2 my-2 msg-lbl">
                                    Lorem ipsum dolor, sit amet consectetur adipisicing
                                    elit. Omnis dolores explicabo iure repudiandae, quo
                                    repellat. Reiciendis facere maxime in commodi
                                    voluptatibus quos hic optio, culpa magni, a, dolorem
                                    recusandae cumque.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            
            <div>
                {
                    isKeyboardVisible
                }
            </div>
            <div className="send-cont">
                <textarea rows={1}></textarea>
                <button className="send-btns">
                    <img style={{height: '20px'}} src={send} />
                </button>
                <AudioRecorder
                        onRecordingComplete={(blob) => addAudioElement(blob)}
                        onClick={() => onRecord(isRecording)}
                        recorderControls={recorderControls}
                        downloadOnSavePress={false}
                        downloadFileExtension="mp3"
                        showVisualizer={true}
                      />
            </div>
        </div>
    );
}

export default Sample;